import ResponseResult from "@/types/classes/ResponseResult";
import { StrapiCmsUser } from "@/types/strapi";
import { axiosGetRequestServerSide } from "@/utils/axiosServerUtil";
import {
  getCmsAccessToken,
  invalidateCmsAuthenticationCookie,
} from "@/utils/serverUtil";
import { IncomingMessage, ServerResponse } from "http";

export const getCurrentCmsUserRequestServerSide = async (
  accesstoken: string
): Promise<ResponseResult<{ data: StrapiCmsUser }>> => {
  const currentCmsUserResult: ResponseResult<{ data: StrapiCmsUser }> =
    await axiosGetRequestServerSide(
      `${global.envVar("PABU_PUBLIC_STRAPI_API_URL")}/admin/users/me`,
      accesstoken
    );

  return currentCmsUserResult;
};

export const checkCmsUserAuthenticationServerSide = async (
  req: IncomingMessage,
  res?: ServerResponse<IncomingMessage>
) => {
  const cmsToken = getCmsAccessToken(req);

  if (!cmsToken) {
    return null;
  }

  const cmsUserResult = await getCurrentCmsUserRequestServerSide(cmsToken);

  if (!cmsUserResult.success) {
    if (res) {
      if (
        cmsUserResult.error &&
        cmsUserResult.error.response &&
        cmsUserResult.error.response.status !== 200
      ) {
        invalidateCmsAuthenticationCookie(res);
      } else if (!cmsUserResult.response) {
        global.log.warn(
          "[checkCmsUserAuthenticationServerSide] No response from strapi. Skipped cookie invalidation."
        );
      }
    }
    return null;
  }

  return cmsUserResult.data.data;
};
