import { SvgIds } from "@/components/pbIcon/svg";
import { isLocaleDefaultLocale } from "@/utils/localizationUtil";
import { useState } from "react";

interface LanguageOption {
  name: string;
  value: string;
  iconName?: SvgIds;
}

export default function useAvailableLocales(defaultLocaleIcon?: boolean) {
  const [languageOptions] = useState<LanguageOption[]>(
    () => {
      if (!global.envVar("PABU_PUBLIC_ACTIVE_LOCALES")) {
        return [];
      }
      const activeLanguages =
        global.envVar("PABU_PUBLIC_ACTIVE_LOCALES")!.split(",");

      return activeLanguages.map(
        (locale: string): LanguageOption => ({
          name: locale.toUpperCase(),
          value: locale,
          iconName:
            defaultLocaleIcon && isLocaleDefaultLocale(locale)
              ? "globe-light"
              : undefined,
        })
      );
    }
  );

  return languageOptions;
}
