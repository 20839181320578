import { getStrapiPrivateUrl } from "@/utils/api";
import { axiosGetRequestServerSide } from "@/utils/axiosServerUtil";

export type LocalesStatus = {
  /**
   * only true if strapi and next locales arrays are the same
   */
  localesInSync: boolean;
  /**
   * the locales that are configured in strapi
   */
  strapiLocales: Array<string>;
  /**
   * the locales that are configured in next
   */
  nextLocales: Array<string>;
};

const getStrapiLocales = async (token: string) => {
  const result = await axiosGetRequestServerSide(
    getStrapiPrivateUrl("/pabu/available-locales"),
    token
  );
  if (!result.success) {
    return null;
  }
  return result.data;
};

/**
 * Checks if both strapi and next locales are the same and returns an object containing this information
 */
export const getLocalesStatus = async (token: string) => {
  const result: LocalesStatus = {
    localesInSync: true,
    strapiLocales: [],
    nextLocales: [],
  };

  if (!token) {
    result.localesInSync = false;
    return result;
  }

  const sortedNextLocales =
    global.envVar("PABU_PUBLIC_ACTIVE_LOCALES")!.split(",") ?? [];
  sortedNextLocales.sort();

  const strapiLocales: Array<{ code: string; name: string }> =
    (await getStrapiLocales(token)) ?? [];
  const sortedStrapiLocales = strapiLocales
    .map((strapiLocale) => strapiLocale.code)
    .sort();

  result.nextLocales = sortedNextLocales;
  result.strapiLocales = sortedStrapiLocales;

  if (sortedNextLocales.length === 0 || sortedStrapiLocales.length === 0) {
    result.localesInSync = false;
    return result;
  }

  if (sortedNextLocales.length !== sortedStrapiLocales.length) {
    result.localesInSync = false;
    return result;
  }

  sortedNextLocales.forEach((nextLocale, index) => {
    if (nextLocale !== sortedStrapiLocales[index]) {
      result.localesInSync = false;
    }
  });

  return result;
};
