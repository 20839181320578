import PbDropdown, {
  PbDropdownItem,
} from "@/components/input/pbDropdown/pbDropdown";

interface CmsAttributeEnumProps {
  name: string;
  value: string;
  options: PbDropdownItem[];
  onChange: (name: string, value: string) => void;
  isNonLocalizedDisabled?: boolean;
  idPrefix: string;
}

export default function CmsAttributeEnum(
  props: Readonly<CmsAttributeEnumProps>
) {
  return (
    <PbDropdown
      id={props.idPrefix + "-" + props.name}
      withCmsStyle
      dropdownList={props.options}
      onChange={(event) =>
        props.onChange(props.name, event.target.value as string)
      }
      defaultValue={props.value}
      disabled={props.isNonLocalizedDisabled}
      itemNameKey="name"
      itemValueKey="value"
    />
  );
}
