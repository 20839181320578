import PbInput from "@/components/input/pbInput/pbInput";

interface CmsAttributeStringProps {
  name: string;
  value: string;
  onChange: (name: string, value: string) => void;
  isNonLocalizedDisabled?: boolean;
  idPrefix: string;
}

export default function CmsAttributeString(
  props: Readonly<CmsAttributeStringProps>
) {
  return (
    <PbInput
      id={props.idPrefix + "-" + props.name}
      withCmsStyle
      type="text"
      defaultValue={props.value}
      fullWidth
      disabled={props.isNonLocalizedDisabled}
      onChange={(e) => {
        props.onChange(props.name, e.target.value);
      }}
    />
  );
}
