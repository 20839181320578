export let publicEnvVars: { [key: string]: string } = {};

export const setPublicEnvVars = (pPublicEnvVars: { [key: string]: string }) => {
  if (pPublicEnvVars) {
    publicEnvVars = pPublicEnvVars;
  } else {
    global.log.warn("Public env vars not set.");
  }
};

export const getEnvVar = (envVarName: string): string | undefined => {
  let envValue = process.env[envVarName];

  if (typeof window === "undefined") {
    // serverside
    if (typeof envValue === "undefined" && envVarName.includes("PABU_PUBLIC")) {
      envValue = process.env[envVarName.replace("PABU_PUBLIC", "NEXT_PUBLIC")];
    }
  } else if (typeof envValue === "undefined") {
    // clientside and envValue is undefined
    envValue = publicEnvVars[envVarName];
    if (typeof envValue === "undefined" && envVarName.includes("PABU_PUBLIC")) {
      envValue =
        publicEnvVars[envVarName.replace("PABU_PUBLIC", "NEXT_PUBLIC")];
    }
  }

  return envValue;
};
