import ResponseResult from "@/types/classes/ResponseResult";
import {
  CreateFormRequest,
  FormFieldBoolean,
  FormFieldDate,
  FormFieldEmail,
  FormFieldEnum,
  FormFieldEnumOptionValue,
  FormFieldFile,
  FormFieldString,
  FormFieldText,
  PbForm,
  UpdateFormRequest,
} from "@/types/pbForm/pbForm";
import { getNextJsApiURL } from "@/utils/api";
import {
  axiosDeleteRequestClientSide,
  axiosGetRequestClientSide,
  axiosPostRequestClientSide,
} from "@/utils/axiosClientUtil";
import { MAX_FORM_FIELD_FILE_SIZE_IN_MB } from "@/utils/constants";
import { getDefaultLocale } from "@/utils/localizationUtil";
import { getRandomSlug } from "@/utils/util";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import { cmsTranslate } from "../cmsTranslation/cmsTranslationService";
import { createEmptyContentElement } from "../pageService/pageService";

export const getFormsRequest = async (): Promise<
  ResponseResult<Array<PbForm>>
> => {
  const result = await axiosGetRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/forms`)
  );
  if (!result.success) {
    global.log.warn(`[getFormsRequest] could not fetch forms`);
    createToastError(cmsTranslate("formsGetError"));
  }
  return result;
};

export const getFormRequest = async (
  formId: number
): Promise<ResponseResult<any>> => {
  const result = await axiosGetRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/forms/${formId}`)
  );
  if (result.success) {
    addUniqueIdsToOptions(result.data.form.fields);
  } else {
    global.log.warn(`[getFormRequest] could not fetch form with id ${formId}`);
    if (result.response?.status === 404) {
      createToastError(cmsTranslate("formDoesNotExist"));
    } else {
      createToastError(cmsTranslate("formsGetError"));
    }
  }

  return result;
};

const addUniqueIdsToOptions = (
  fields: Array<
    | FormFieldString
    | FormFieldEmail
    | FormFieldBoolean
    | FormFieldText
    | FormFieldFile
    | FormFieldDate
    | FormFieldEnum
  >
) => {
  if (fields) {
    fields.forEach(
      (
        element:
          | FormFieldString
          | FormFieldEmail
          | FormFieldBoolean
          | FormFieldText
          | FormFieldFile
          | FormFieldDate
          | FormFieldEnum,
        index: number
      ) => {
        if (element.__component === "pb.frmnm") {
          let formFieldEnum = fields[index] as FormFieldEnum;
          if (!formFieldEnum.fieldDropdownValues) {
            formFieldEnum.fieldDropdownValues =
              [] as Array<FormFieldEnumOptionValue>;
          } else {
            formFieldEnum.fieldDropdownValues.forEach((option, optionIndex) => {
              formFieldEnum.fieldDropdownValues[optionIndex] = {
                ...option,
                __new_id: getRandomSlug(),
              };
            });
          }
        }
      }
    );
  }
};

export const getFormCreateValuesRequest = async () => {
  const result = await axiosGetRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/forms/create/values`)
  );
  return result;
};

export const createFormRequest = async (
  editForm: CreateFormRequest,
  creatorName: string
): Promise<ResponseResult<PbForm>> => {
  const result: ResponseResult<PbForm> = await axiosPostRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/forms/create`),
    { ...editForm, creator: creatorName }
  );
  if (!result.success) {
    createToastError(cmsTranslate("formsCreateError"));
  } else {
    createToastSuccess(cmsTranslate("formsCreateSuccess"));
  }
  return result;
};

export const updateFormRequest = async (
  editForm: UpdateFormRequest
): Promise<ResponseResult<PbForm>> => {
  const result: ResponseResult<PbForm> = await axiosPostRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/forms/update`),
    editForm
  );
  if (!result.success) {
    createToastError(cmsTranslate("formsUpdateError"));
  } else {
    createToastSuccess(cmsTranslate("formsUpdateSuccess"));
  }
  return result;
};

export const deleteFormRequest = async (
  formId: number
): Promise<ResponseResult<PbForm>> => {
  const result: ResponseResult<PbForm> = await axiosDeleteRequestClientSide(
    getNextJsApiURL(`/cms/manage/pabu/forms/delete/${formId}`)
  );
  return result;
};

export const createEmptyFormField = (formFieldAttributes: any) => {
  const newElement: any = createEmptyFormFieldElement(formFieldAttributes);
  delete newElement.attributes;
  if (newElement.hasOwnProperty("fieldDropdownValues")) {
    newElement.fieldDropdownValues = [];
  }
  newElement.__component = formFieldAttributes.name;
  return newElement;
};

const createEmptyFormFieldElement = (element: any) => {
  return createEmptyContentElement(element, true);
};

/**
 * checks if cfgFieldCharactersMin is greater 0 and if cfgFieldCharactersMax is greater than cfgFieldCharactersMin
 * @param {Object} formField
 * @returns ture or false
 */
const checkMinConfigHasError = (formField: any) => {
  let minInputValue = formField["cfgFieldCharactersMin"];
  let maxInputValue = formField["cfgFieldCharactersMax"];

  if (minInputValue !== null && minInputValue < 0) {
    return true;
  }

  return checkMaxMinConfigHasError(minInputValue, maxInputValue);
};

/**
 * checks if cfgFieldCharactersMax is greater 0 and if cfgFieldCharactersMax is greater than cfgFieldCharactersMin
 * @param {Object} formField
 * @returns ture or false
 */
const checkMaxConfigHasError = (formField: any) => {
  let minInputValue = formField["cfgFieldCharactersMin"];
  let maxInputValue = formField["cfgFieldCharactersMax"];

  if (maxInputValue !== null && maxInputValue < 0) {
    return true;
  }

  return checkMaxMinConfigHasError(minInputValue, maxInputValue);
};

/**
 * checks if maxInputValue ist greater than minInputValue
 * @param {Number} minInputValue
 * @param {Number} maxInputValue
 * @returns
 */
const checkMaxMinConfigHasError = (
  minInputValue: number,
  maxInputValue: number
) => {
  return (
    maxInputValue !== null &&
    minInputValue !== null &&
    minInputValue > maxInputValue
  );
};

/**
 * checks if the fileSize set by the CM is lower than the max allowed file size and a number and the value is positiv
 * @param {Object} formField
 * @returns
 */
const checkMaxFileSizeConfigHasError = (formField: any) => {
  const maxFileSizeValue = formField["cfgFieldMaxFileSizeInMB"];

  if (maxFileSizeValue !== null && !isNaN(maxFileSizeValue)) {
    if (
      maxFileSizeValue < 0 ||
      maxFileSizeValue > MAX_FORM_FIELD_FILE_SIZE_IN_MB
    ) {
      return true;
    }

    if (!Number.isInteger(maxFileSizeValue)) {
      return true;
    }
  }
  return false;
};

/**
 * check if a formField is a negativ value, that should not be negativ
 * @param {Object} formField
 * @param {String} label
 * @returns true if negativ else false
 */
const checkNegativNumber = (formField: any, label: string) => {
  return formField[label] < 0;
};

/**
 * calls the correct validation method depending on what integer config form field needs validation
 * @param {Object} formField
 * @param {String} label
 * @returns true if the field has an error, else false
 */
export const checkConfigIntegerEditFieldHasError = (
  formField: any,
  label: string
) => {
  switch (label) {
    case "cfgFieldCharactersMin":
      return checkMinConfigHasError(formField);
    case "cfgFieldCharactersMax":
      return checkMaxConfigHasError(formField);
    case "cfgFieldMaxFileSizeInMB":
      return checkMaxFileSizeConfigHasError(formField);
    case "cfgMaxDaysPast":
    case "cfgMaxDaysFuture":
      return checkNegativNumber(formField, label);
    default:
      return false;
  }
};

/**
 * Checks if the form field config has errors
 * @param {Object} formField
 * @returns true or false
 */
export const checkFormFieldConfigHasError = (formField: any) => {
  let hasError = false;
  Object.keys(formField).forEach((key) => {
    if (key === "cfgFieldCharactersMin" || "cfgFieldCharactersMax") {
      if (checkConfigIntegerEditFieldHasError(formField, key)) {
        hasError = true;
      }
    }
  });
  return hasError;
};

/**
 * Checks whether a fieldName is unique or not.
 * @param fieldName
 * @param formFields
 * @returns
 */
export const checkIsFieldNameUnique = (
  fieldName: string,
  formFields: Array<any>
) => {
  const fieldNameCount = formFields.reduce((acc, obj) => {
    if (obj["cfgFieldName"] === fieldName) {
      acc++;
    }
    return acc;
  }, 0);

  return !(fieldNameCount > 1);
};

/**
 * checks if a checkbox should be disabled
 * @param {Object} formField
 * @param {String} label
 * @returns true or false to disable the checkbox
 */
export const disableCheckboxConfigField = (formField: any, label: string) => {
  switch (label) {
    case "cfgFieldNoNumbers":
      return (
        formField["cfgFieldNoLetters"] &&
        formField["cfgFieldNoSpecialCharacters"]
      );
    case "cfgFieldNoLetters":
      return (
        formField["cfgFieldNoNumbers"] &&
        formField["cfgFieldNoSpecialCharacters"]
      );
    case "cfgFieldNoSpecialCharacters":
      return formField["cfgFieldNoNumbers"] && formField["cfgFieldNoLetters"];
    default:
      return false;
  }
};

const configValidateTextFieldOrder = [
  "cfgFieldIsMandatory",
  "cfgFieldCharactersMin",
  "cfgFieldCharactersMax",
  "cfgFieldNoLetters",
  "cfgFieldNoNumbers",
  "cfgFieldNoSpecialCharacters",
];

const configValidateStringFieldOrder = [
  "cfgFieldIsMandatory",
  "cfgFieldCharactersMin",
  "cfgFieldCharactersMax",
  "cfgFieldNoLetters",
  "cfgFieldNoNumbers",
  "cfgFieldNoSpecialCharacters",
  "cfgFieldNoWhitespaceCharacters",
  "cfgFieldCustomRegex",
];

const configValidateFileFieldOrder = [
  "cfgFieldIsMandatory",
  "cfgFieldAllowedFileEndings",
  "cfgFieldMaxFileSizeInMB",
];

const configValidateDateFieldOrder = [
  "cfgFieldIsMandatory",
  "cfgMaxDaysPast",
  "cfgMaxDaysFuture",
  "cfgCalendarStart",
  "cfgNotAllowedDatesStart",
  "cfgNotAllowedDatesEnd",
];

const configValidateDefaultFieldOrder = ["cfgFieldIsMandatory"];

const generalConfigFieldOrder = [
  "fieldLabel",
  "cfgFieldPlaceholder",
  "cfgFieldCustomErrorMessage",
  "fieldRichTextDescription",
  "cfgIsLabelFieldName",
  "cfgFieldName",
];

/**
 * returns the order of the gernal forms config fields depending on what config fields the form field component has
 * @param {Object} fieldAttributes
 * @returns array of ordered general forms config fields
 */
export const getGeneralConfigFieldOrder = (fieldAttributes: any) => {
  return generalConfigFieldOrder.filter((orderedConfigField) => {
    return checkOrderedFieldIsContained(fieldAttributes, orderedConfigField);
  });
};

/**
 * get the correct ordered config field order depending on the form field component
 * @param {String} formfieldComponent
 * @returns array
 */
export const getValidationConfigFieldOrderArray = (formfieldComponent: any) => {
  switch (formfieldComponent) {
    case "pb.frmfl":
      return configValidateFileFieldOrder;
    case "pb.frmstr":
      return configValidateStringFieldOrder;
    case "pb.frmtxt":
      return configValidateTextFieldOrder;
    case "pb.frmdt":
      return configValidateDateFieldOrder;
    default:
      return configValidateDefaultFieldOrder;
  }
};

/**
 * checks if an config field is contained in the ordered config field array
 * @param {Object} fieldAttributes
 * @param {Array} orderedConfigField
 * @returns true or false
 */
const checkOrderedFieldIsContained = (
  fieldAttributes: any,
  orderedConfigField: any
) => {
  let isContained = false;
  Object.keys(fieldAttributes).forEach((key) => {
    if (orderedConfigField === key) {
      isContained = true;
    }
  });

  return isContained;
};

export const openFormsEditInNewTab = async (
  formsId: number,
  locale: string | undefined
) => {
  if (!locale) {
    locale = getDefaultLocale();
  }
  const result = await getFormRequest(formsId);
  if (result.success) {
    if (result.data.form.locale === getDefaultLocale()) {
      window.open(
        `${global.envVar("PABU_PUBLIC_NEXTJS_URL")}/cms/forms/edit/${result.data.form.id}`
      );
    } else {
      const localizedForm = result.data.form.localizations.find(
        (localization: any) => localization.locale === getDefaultLocale()
      );
      window.open(
        `${global.envVar("PABU_PUBLIC_NEXTJS_URL")}/${locale}/cms/forms/edit/${localizedForm.id}`
      );
    }
  }
};
