import {
  AnyAction,
  MiddlewareArray,
  ThunkMiddleware,
  configureStore,
  type Action,
  type ThunkAction,
} from "@reduxjs/toolkit";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { useMemo } from "react";
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  type TypedUseSelectorHook,
} from "react-redux";
import { debounceMiddleware, loggerMiddleware } from "./middleware";
import { reducer } from "./rootReducer";
import { CmsEditState } from "./slices/cmsEdit/cmsEditSlice";
import { CmsGeneralState } from "./slices/cmsGeneral/cmsGeneralSlice";
import { CmsUserState } from "./slices/cmsUser/cmsUserSlice";
import { GeneralState } from "./slices/general/generalSlice";

/**
 * current redux implementation:
 *
 * Every time a page is rendered serverside the redux state will be set
 * to the initial redux state from _app.tsx. The initial _app.tsx
 * should represent the entire redux state.
 *
 * On every getServerSideProps on each page you can set another initial
 * redux state that overrides the initial state created from_app.tsx
 * by passing props: { initialReduxState } within props.
 * On page level you should also only pass entire redux states.
 *
 * If you have a redux state in the client and you open a client
 * side rendered page (a page without getServerSideProps) the redux
 * state stays the same while navigating to this page.
 *
 * Each full page refresh will result in a new initial redux state
 */
export let reduxStore: ToolkitStore<
  {
    general: GeneralState;
    cmsUser: CmsUserState;
    cmsEdit: CmsEditState;
    cmsGeneral: CmsGeneralState;
  },
  AnyAction,
  MiddlewareArray<
    [
      ThunkMiddleware<
        {
          general: GeneralState;
          cmsUser: CmsUserState;
          cmsEdit: CmsEditState;
          cmsGeneral: CmsGeneralState;
        },
        AnyAction
      >
    ]
  >
>;

function initStore(initialState: ReduxState): ToolkitStore {
  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => {
      if (global.envVar("PABU_PUBLIC_CURRENT_ENVIRONMENT") === "prod") {
        /* @ts-ignore */
        return getDefaultMiddleware().concat(debounceMiddleware());
      }
      /* @ts-ignore */
      return getDefaultMiddleware()
        .concat(debounceMiddleware())
        .concat(loggerMiddleware);
    },
    devTools: global.envVar("PABU_PUBLIC_CURRENT_ENVIRONMENT") !== "prod",
    preloadedState: initialState,
  });
}

export const initializeStore = (preloadedState: ReduxState) => {
  let _store = reduxStore ?? initStore(preloadedState);

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") {
    return _store;
  }

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && reduxStore) {
    _store = initStore({
      ...reduxStore.getState(),
      ...preloadedState,
    });
    // Reset the current store
    reduxStore = _store;
  }

  // Create the store once in the client
  if (!reduxStore) {
    reduxStore = _store;
  }

  return _store;
};

export const useAppDispatch = () => useReduxDispatch<ReduxDispatch>();
export const useAppSelector: TypedUseSelectorHook<ReduxState> =
  useReduxSelector;

export function useStore(initialState: ReduxState): ToolkitStore {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}

/* Types */
export type ReduxStore = typeof reduxStore;
export type ReduxState = ReturnType<typeof reduxStore.getState>;
export type ReduxDispatch = typeof reduxStore.dispatch;
export type ReduxThunkAction<ReturnType = Promise<any> | void> = ThunkAction<
  ReturnType,
  ReduxState,
  unknown,
  Action
>;

export type ReduxCallbackFunctions = {
  success?: Function;
  error?: Function;
};
