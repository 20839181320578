import { cmsTranslate } from "@/services/cmsTranslation/cmsTranslationService";
import ResponseResult from "@/types/classes/ResponseResult";
import { createToastError } from "@/utils/utilComponents";
import axios, { AxiosRequestConfig } from "axios";
import { deepMerge } from "./util";

/**
 * Axios request configuration for client-side requests.
 *
 * This configuration is intended to be used with Axios for making HTTP requests on the client side.
 *
 * @type {AxiosRequestConfig}
 * @property {Object} headers - Request headers.
 *   @property {string} Cache-Control - Specifies the cache control directive (e.g., "no-cache").
 *   @property {string} Pragma - Specifies the Pragma directive (e.g., "no-cache").
 *   @property {string} Expires - Specifies the Expires directive (e.g., "0").
 */
export const globalAxiosConfigClientSide: AxiosRequestConfig = {
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
};

/**
 * Creates an Axios request configuration object by merging a custom Axios
 * configuration with global Axios client configuration
 *
 * @param {AxiosRequestConfig} customAxiosConfig - Custom Axios request
 * configuration to be merged with global configuration.
 * @returns {AxiosRequestConfig} The merged Axios request configuration object.
 */
const createAxiosConfigClientSide = (
  accessToken: string | undefined,
  customAxiosConfig: AxiosRequestConfig = {}
): AxiosRequestConfig => {
  let newReqAxiosConfig = {
    ...globalAxiosConfigClientSide,
    headers: { ...globalAxiosConfigClientSide.headers },
  };
  // merge custom config with global config (custom config will override global keys)
  newReqAxiosConfig = deepMerge(newReqAxiosConfig, customAxiosConfig);
  // add Authorization header to the axios config
  addAuthorizationHeaderClientSide(newReqAxiosConfig, accessToken);
  return newReqAxiosConfig;
};

/**
 * Adds an Authorization header to the Axios request configuration if an access token
 * is provided.
 *
 * @param {string|undefined} accessToken - The access token to be included in the
 * Authorization header.
 * @param {AxiosRequestConfig} requestConfig - The Axios request configuration to which
 * the Authorization header will be added.
 * @returns {AxiosRequestConfig} The modified Axios request configuration with the
 * Authorization header, if applicable.
 */
const addAuthorizationHeaderClientSide = (
  requestConfig: AxiosRequestConfig,
  accessToken?: string | undefined
): AxiosRequestConfig => {
  const newRequestConfig = { ...requestConfig };
  if (accessToken) {
    newRequestConfig.headers!.Authorization = `Bearer ${accessToken}`;
  }
  return newRequestConfig;
};

/**
 * general axios get request function
 * TODO this is no final version this might change later
 *
 * @param {} url
 * @param {*} accessToken
 * @param {*} customAxiosConfig
 * @returns
 */
export const axiosGetRequestClientSide = async (
  url: string,
  accessToken?: string,
  customAxiosConfig: AxiosRequestConfig = {}
): Promise<ResponseResult<any>> => {
  return await axiosRequestClientSide(() =>
    axios.get(url, createAxiosConfigClientSide(accessToken, customAxiosConfig))
  );
};

/**
 * general axios get request function
 * TODO this is no final version this might change later
 *
 * @param {} url
 * @param {*} accessToken
 * @param {*} customAxiosConfig
 * @returns
 */
export const axiosPostRequestClientSide = async (
  url: string,
  body: any,
  accessToken?: string,
  customAxiosConfig: AxiosRequestConfig = {}
): Promise<ResponseResult<any>> => {
  return await axiosRequestClientSide(() =>
    axios.post(
      url,
      body,
      createAxiosConfigClientSide(accessToken, customAxiosConfig)
    )
  );
};

export const axiosPutRequestClientSide = async (
  url: string,
  body: any,
  accessToken?: string,
  customAxiosConfig: AxiosRequestConfig = {}
): Promise<ResponseResult<any>> => {
  return await axiosRequestClientSide(() =>
    axios.put(
      url,
      body,
      createAxiosConfigClientSide(accessToken, customAxiosConfig)
    )
  );
};

export const axiosDeleteRequestClientSide = async (
  url: string,
  accessToken?: string,
  customAxiosConfig: AxiosRequestConfig = {}
): Promise<ResponseResult<any>> => {
  return await axiosRequestClientSide(() =>
    axios.delete(
      url,
      createAxiosConfigClientSide(accessToken, customAxiosConfig)
    )
  );
};

const axiosRequestClientSide = async (
  requestFn: () => Promise<any>
): Promise<ResponseResult<any>> => {
  try {
    const response = await requestFn();
    return new ResponseResult(response.data, response);
  } catch (error: any) {
    handleRequestErrorClientSide(error, "");
    if (error && error.response) {
      return ResponseResult.fromError(error);
    }
    return ResponseResult.fromError(error);
  }
};

export const handleRequestErrorClientSide = (
  error: any,
  customLog?: string
): void => {
  if (global.envVar("PABU_PUBLIC_CURRENT_ENVIRONMENT") === "local") {
    if (customLog) {
      global.log.debug("[handleRequestErrorClientSide] " + customLog);
    }
  }
  if (error) {
    if (error.response && error.response.status === 403) {
      createToastError(cmsTranslate("permissionDenied"));
    }

    global.log.error("[handleRequestErrorClientSide] " + error.message);
  }
};

/**
 *
 * get the strapi error object (clientSide)
 *
 * e.g.:
 *  {
 *   status: 413,
 *   error: 'Request Entity Too Large',
 *   message: 'FileTooBig',
 *  }
 *
 * if the strapi error.response.data is not present this
 * function will create a temporary error object with some
 * request config information
 *
 * @param {*} error
 * @returns
 */
export const getErrorResponseObjectClientSide = (error: any) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error
  ) {
    return error.response.data.error;
  }
  const errorMessage = `${
    error.config && error.config.url ? ` ${error.config.url}` : ""
  } - ${error.config && error.config.method ? ` ${error.config.method}` : ""}`;
  return {
    status: -1,
    error: errorMessage,
    message: errorMessage,
  };
};

/**
 * gets the correct status from a strapi error object (clientSide)
 * otherwise it will always return status code 400
 *
 * @param {*} error
 * @returns
 */
export const getErrorResponseStatusCodeClientSide = (error: any) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.status
  ) {
    return error.response.data.error.status;
  }

  if (error.response.status) {
    global.log.error(
      `[getErrorResponseStatusCodeClientSide] could not get status from error error.response.data object returning status from actual response (${error.response.status})`
    );
    return error.response.status;
  }

  global.log.error(
    "[getErrorResponseStatusCodeClientSide] could not get status returning default code 400"
  );
  return 400;
};
