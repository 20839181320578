import PbButton from "@/components/input/pbButton/pbButton";
import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import PbDropdown, {
  PbDropdownItem,
} from "@/components/input/pbDropdown/pbDropdown";
import PbInputControlled from "@/components/input/pbInputControlled/pbInputControlled";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import { getPageTypeNameLocalized } from "@/services/cmsPageTypeService/cmsPageTypeService";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import {
  cmsResetDraftPageContentToDefaultLocaleThunk,
  cmsResetDraftPageToPublishedThunk,
} from "@/store/slices/cmsEdit/cmsPageThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { DEFAULT_AS_SELECTABLE_VALUE } from "@/utils/constants";
import {
  getDefaultLocale,
  isLocaleDefaultLocale,
} from "@/utils/localizationUtil";
import {
  isPageUrlRootUrl,
  isRedirectUrlPointingToPageUrl,
} from "@/utils/urlUtil";
import { isStoreValueDefaultAllowed } from "@/utils/util";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import { useRouter } from "next/router";

interface CmsPageSettingsProps {
  data: any;
  onChange: (name: string, value: any) => void;
}

export default function CmsPageSettings(props: Readonly<CmsPageSettingsProps>) {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  const router = useRouter();

  const editView = useAppSelector((state) => state.cmsGeneral.editView);

  const canEditUrl =
    !isPageUrlRootUrl(props.data.url) && isLocaleDefaultLocale(router.locale!);

  const getDefaultValue = (values: any) => {
    if (!values || (values && values.length === 0)) {
      return undefined;
    }
    if (isStoreValueDefaultAllowed(values)) {
      return -1 + "";
    }
    return values.at(0) + "";
  };

  const backgroundDropdownItems = () => {
    return globalConfig?.layout.bgImages?.data
      ? Object.entries(globalConfig?.layout.bgImages.data)
          .map(([key, value]): PbDropdownItem | undefined => {
            if (key === `${DEFAULT_AS_SELECTABLE_VALUE}`) {
              return {
                value: key,
                name: tCms("configModal-defaultValue"),
              };
            }
            if (!value) {
              return undefined;
            }
            return {
              value: key,
              name: value.strname,
            };
          })
          .filter((item) => item !== undefined)
          .map((item) => item as PbDropdownItem)
      : [];
  };

  const backgroundOptions = backgroundDropdownItems();
  const { showConfirmModal: showResetToDefaultLocaleModal } = useConfirmModal(
    async () => {
      if (
        !isLocaleDefaultLocale(router.locale!) &&
        props.data &&
        props.data.id
      ) {
        try {
          await dispatch(
            cmsResetDraftPageContentToDefaultLocaleThunk(
              props.data.id,
              global.envVar("PABU_PUBLIC_DEFAULT_LOCALE")!
            )
          );
          createToastSuccess(tCms("page-synchronizeSuccess"));
        } catch (error: any) {
          global.log.error(error);
          createToastError(tCms("page-synchronizeError"));
        }
      }
    }
  );

  const { showConfirmModal: showResetToPublishedModal } = useConfirmModal(
    async () => {
      if (props.data && props.data.id) {
        try {
          await dispatch(cmsResetDraftPageToPublishedThunk());
          createToastSuccess(tCms("page-resetToPublishedSuccess"));
        } catch (error: any) {
          global.log.error(error);
          createToastError(tCms("page-resetToPublishedError"));
        }
      }
    }
  );

  return (
    <div className="cms-modal-input-wrapper-container">
      <ModalInputWrapper
        label={tCms("pageType")}
        description={tCms("pageTypeDescription")}
      >
        {props.data.type && (
          <div className="d-flex">
            <span
              style={{
                marginRight: "7px",
              }}
              title={
                tCms("pageType") +
                ": " +
                (getPageTypeNameLocalized(
                  props.data.type,
                  global.envVar("PABU_PUBLIC_CMS_USER_LANGUAGE")!
                ) ?? "")
              }
            >
              <PbIcon
                width={15}
                height={15}
                name="table-layout-light"
                style={{ fill: props.data.type.color ?? "var(--pb-cms-color)" }}
              />
            </span>

            <p style={{ color: "var(--pb-cms-variable-black)" }}>
              <b>
                {getPageTypeNameLocalized(
                  props.data.type,
                  global.envVar("PABU_PUBLIC_CMS_USER_LANGUAGE")!
                )}
              </b>
            </p>
          </div>
        )}
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("name")}
        description={tCms("page-typePageNameHere")}
      >
        <PbInputControlled
          id="cms-page-settings-modal-name-field"
          withCmsStyle
          label={""}
          value={props.data.name}
          onChange={(e) => {
            props.onChange("name", e.target.value);
          }}
          fullWidth
        />
      </ModalInputWrapper>
      {editView && (
        <ModalInputWrapper
          label={tCms("url")}
          description={tCms("page-addPageUrlHere")}
          nonLocalized
        >
          <PbInputControlled
            id="cms-page-settings-modal-url-field"
            withCmsStyle
            startAdornment={
              props.data.type?.contextPath ? (
                <div className="cms-input-startadornment-chip">
                  <div
                    className="chip-text"
                    title={props.data.type.contextPath + "/"}
                  >
                    {props.data.type.contextPath + "/"}
                  </div>
                </div>
              ) : undefined
            }
            fullWidth
            label={""}
            disabled={!canEditUrl}
            value={props.data.url.replace(
              props.data.type.contextPath + "/",
              ""
            )}
            type="text"
            onChange={(event) => {
              let url = event.target.value;
              if (props.data.type?.contextPath) {
                url = props.data.type.contextPath + "/" + event.target.value;
              }
              props.onChange("url", url);
            }}
          ></PbInputControlled>
        </ModalInputWrapper>
      )}
      <ModalInputWrapper
        label={tCms("page-disableNavigation")}
        description={tCms("page-selectDisabledNavigation")}
      >
        <PbCheckBox
          id="cms-page-settings-modal-navigation-slider"
          isSlider
          value={!props.data.hasNavigation}
          onChange={(event) => {
            props.onChange("hasNavigation", !event.target.checked);
          }}
          fullHeight
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("page-disableFooter")}
        description={tCms("page-selectDisableFooter")}
      >
        <PbCheckBox
          id="cms-page-settings-modal-footer-slider"
          isSlider
          value={!props.data.hasFooter}
          onChange={(event) => {
            props.onChange("hasFooter", !event.target.checked);
          }}
          fullHeight
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("page-redirectEnabled")}
        description={tCms("page-redirectEnabledDescription")}
        nonLocalized
      >
        <PbCheckBox
          id="cms-page-settings-modal-redirect-slider"
          isSlider
          isDisabled={editView && !isLocaleDefaultLocale(router.locale!)}
          value={props.data.redirectEnabled}
          onChange={(event) => {
            props.onChange("redirectEnabled", !props.data.redirectEnabled);
          }}
          fullHeight
        />
      </ModalInputWrapper>

      <ModalInputWrapper
        label={tCms("page-redirectUrl")}
        description={tCms("page-redirectUrlDescription")}
        nonLocalized
      >
        <PbInputControlled
          id="cms-page-settings-modal-redirect-url-field"
          withCmsStyle
          label={""}
          disabled={
            !props.data.redirectEnabled ||
            (editView && !isLocaleDefaultLocale(router.locale!))
          }
          value={props.data.redirectUrl ?? ""}
          onChange={(e) => {
            props.onChange("redirectUrl", e.target.value);
          }}
          fullWidth
          error={editView && isRedirectUrlPointingToPageUrl(props.data)}
          errorText={tCms("page-redirectUrlIsSameAsUrl")}
        />
      </ModalInputWrapper>
      {globalConfig?.layout.bgImages &&
      globalConfig?.layout.bgImages.values.length > 1 ? (
        <ModalInputWrapper
          label={tCms("page-background")}
          description={tCms("page-selectBackground")}
        >
          <>
            <PbDropdown
              id="cms-page-settings-modal-background-dropdown"
              withCmsStyle
              value={
                props.data.strBgImage ? props.data.strBgImage.values : undefined
              }
              onChange={(event) => {
                const selectedValue = parseInt(event.target.value as string);
                props.onChange("strBgImage", {
                  store: "str",
                  storeType: "background",
                  values: selectedValue ? [selectedValue] : [],
                });
              }}
              defaultValue={getDefaultValue(
                props.data.strBgImage ? props.data.strBgImage.values : undefined
              )}
              dropdownList={backgroundOptions}
              itemNameKey="name"
              itemValueKey="value"
            ></PbDropdown>
            {props.data.strBgImage &&
            props.data.strBgImage.values &&
            props.data.strBgImage.values[0] &&
            props.data.strBgImage.values[0] != DEFAULT_AS_SELECTABLE_VALUE ? (
              <div
                className={`str-background-${
                  props.data.strBgImage && props.data.strBgImage.values[0]
                    ? props.data.strBgImage.values[0]
                    : undefined
                }-preview`}
                style={{
                  marginTop: "1rem",
                  height: "200px",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              ></div>
            ) : null}
          </>
        </ModalInputWrapper>
      ) : null}
      {editView && !isLocaleDefaultLocale(router.locale!) && (
        <ModalInputWrapper
          label={tCms("page-synchronize", {
            defaultLocale: tCms(getDefaultLocale()),
          })}
          description={tCms("page-syncToDefaultLocalePage", {
            defaultLocale: tCms(getDefaultLocale()),
          })}
        >
          <PbButton
            id="cms-page-settings-modal-sync-btn"
            value={props.data.content}
            className="mx-1"
            onClick={() => {
              showResetToDefaultLocaleModal({
                title: tCms("page-confirmSyncToDefaultLocalePage"),
                content: tCms("page-confirmSyncToDefaultLocalePageLong", {
                  defaultLocale: tCms(getDefaultLocale()),
                }),
                acceptBtnText: tCms("yes"),
                denyBtnText: tCms("no"),
                icon: "triangle-exclamation-light",
              });
            }}
          >
            {tCms("syncButton")}
          </PbButton>
        </ModalInputWrapper>
      )}

      {editView && (
        <ModalInputWrapper
          label={tCms("page-resetToPublished")}
          description={tCms("page-resetToPublishedDescription")}
        >
          <PbButton
            id="cms-page-settings-modal-reset-btn"
            onClick={() => {
              showResetToPublishedModal({
                title: tCms("page-confirmResetToPublished"),
                content: tCms("page-confirmResetToPublishedLong"),
                acceptBtnText: tCms("yes"),
                denyBtnText: tCms("no"),
                icon: "triangle-exclamation-light",
              });
            }}
          >
            {tCms("resetButton")}
          </PbButton>
        </ModalInputWrapper>
      )}
    </div>
  );
}
