import PbDateInput from "@/components/input/pbDateInput/pbDateInput";
import PbTimeInput from "@/components/input/pbTimeInput/pbTimeInput";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import withStyle from "@/middleware/withStyle";
import clsx from "clsx";
import { Col, Row } from "react-bootstrap";

/**
 * Properties for the PbDateTime component.
 */
export interface PbDateTimeProps {
  /**
   * The selected date value as string.
   */
  value: string;

  /**
   * The default date value.
   */
  defaultValue?: Date;

  /**
   * The minimum selectable date.
   */
  minDate?: Date;

  /**
   * The maximum selectable date.
   */
  maxDate?: Date;

  /**
   * Event handler for date change.
   */
  onChange: (value: string | Date | null, type: "date" | "time") => void;

  /**
   * Flag to hide the time input.
   */
  hideTime?: boolean;

  /**
   * input disabled flag
   */
  disabled?: boolean;

  fullWidth?: boolean;

  /**
   * Toggles usage of CmsThemeProvider and sets a class
   * @see withStyle
   */
  withCmsStyle?: boolean;

  /**
   * Toggles usage of PublicThemeProvider and sets a class
   */
  withPublicStyle?: boolean;
  id?: string;
}

const PbDateTime = (props: PbDateTimeProps) => {
  const tCms = useCmsTranslation();

  // if a minDate or maxDate is given it compares value/defaultValue as dates against them
  // and returns a boolean if the current value is inside the gap
  const getIsTimeValid = () => {
    let result = true;
    let timeDateValue = props.value
      ? new Date(props.value)
      : props.defaultValue
      ? new Date(props.defaultValue)
      : null;
    let minDateTimeValue = props.minDate ? new Date(props.minDate) : null;
    let maxDateTimeValue = props.maxDate ? new Date(props.maxDate) : null;
    if (timeDateValue) {
      if (minDateTimeValue && minDateTimeValue > timeDateValue) {
        result = false;
      }
      if (maxDateTimeValue && maxDateTimeValue < timeDateValue) {
        result = false;
      }
    }
    return result;
  };
  const isTimeValid = getIsTimeValid();
  const hours = String(new Date(props.value).getHours()).padStart(2, "0");
  const minutes = String(new Date(props.value).getMinutes()).padStart(2, "0");
  const time = `${hours}:${minutes}`;

  return (
    <>
      <Row
        className={clsx(
          "mb-2",
          props.withCmsStyle && "with-cms-style",
          props.withPublicStyle && "with-public-style"
        )}
      >
        <Col>
          <PbDateInput
            id={props.id}
            fullWidth={props.fullWidth}
            disabled={props.disabled}
            minDate={props.minDate}
            value={props.value}
            onChange={(value: string | Date | null) =>
              props.onChange(value, "date")
            }
            name="event-datepicker"
          />
        </Col>
      </Row>
      {!props.hideTime && (
        <Row>
          <Col>
            <PbTimeInput
              fullWidth={props.fullWidth}
              disabled={props.disabled}
              error={!isTimeValid}
              value={time}
              onChange={(value: string | Date | null) =>
                props.onChange(value, "time")
              }
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          {!isTimeValid && (
            <span style={{ color: "red", fontSize: 12 }}>
              {tCms("event-dateIsBeforeStartDate")}
            </span>
          )}
        </Col>
      </Row>
    </>
  );
};

export default withStyle(PbDateTime);
