import PbInputLabel from "@/components/input/pbInputLabel/pbInputLabel";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import FilePlaceholderIcon from "@/components/util/filePlaceholderIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { isImage } from "@/utils/imageUtil";
import { createToast } from "@/utils/utilComponents";
import Clear from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useRef, useState } from "react";

/**
 * Represents the props for the PbFileInput component.
 */
export interface PbFileInputProps {
  /**
   * The label for the input.
   */
  label?: string;

  /**
   * The maximum height for the file preview.
   */
  previewMaxHeight?: string;

  /**
   * Indicates whether to show the remove button.
   */
  showRemoveBtn?: boolean;

  /**
   * Indicates whether to show the file preview.
   */
  showPreview?: boolean;

  /**
   * The maximum width below which only the file icon is shown.
   */
  showOnlyIconBelowWidth?: number;

  /**
   * The callback function to reset the input on click.
   */
  resetOnClick?: boolean;

  /**
   * The callback function called when the file is changed.
   */
  onChange: (event: any, files: any[]) => void;

  /**
   * The callback function called when the file is removed.
   */
  onRemoveFile?: (event: any) => void;

  /**
   * Whether to allow multiple file selection.
   */
  multiple?: boolean;
}

/**
 * PbFileInput component for handling file selection and display.
 * @param props - The component properties.
 * @returns JSX element representing the PbFileInput component.
 */
const PbFileInput = (props: PbFileInputProps) => {
  const tCms = useCmsTranslation();

  const fileInput = useRef<any>(null);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

  const openFileInput = () => {
    if (props.resetOnClick) {
      fileInput.current.value = null;
    }
    fileInput.current.click();
  };

  const onChange = (event: any) => {
    const files = Array.from(event.target.files);

    if (files.length > 0) {
      if (props.multiple) {
        // Handle multiple files
        const validFiles = files.filter((file: any) => file.name.includes("."));
        if (validFiles.length !== files.length) {
          createToast({
            type: "warning",
            msg: tCms("filenameIsWrong"),
          });
          onRemoveFile(event);
          return;
        }
        setSelectedFiles(validFiles);
        props.onChange(event, validFiles);
      } else {
        // Handle single file (existing logic)
        const file: any = files[0];
        if (!file.name.includes(".")) {
          createToast({
            type: "warning",
            msg: tCms("filenameIsWrong"),
          });
          onRemoveFile(event);
          return;
        }
        setSelectedFiles([file]);
        props.onChange(event, [file]);
      }
    }
  };

  const onRemoveFile = (event: any) => {
    if (selectedFiles.length > 0) {
      fileInput.current.value = null;
      setSelectedFiles([]);
    }
    if (props.onRemoveFile) {
      props.onRemoveFile(event);
    }
  };

  /**
   * this function is needed because if you set an svg as source you
   * need to set the <img> height or width
   * @returns the attribute value of <img height="auto" / height="100px"
   */
  const getPreviewImgHeightAttributeValue = () => {
    let heightValue = props.previewMaxHeight ? props.previewMaxHeight : "100px";
    if (selectedFiles[0]?.type === "image/svg+xml") {
      return heightValue;
    }
    return "auto";
  };

  return (
    <>
      {props.label && <PbInputLabel label={props.label} />}
      <div className="d-flex">
        <div className="my-auto">
          <Button
            disableElevation
            className="cms-default-button cms-file-input-btn"
            variant="contained"
            onClick={openFileInput}
          >
            <span>
              <PbIcon
                name="upload-light"
                className={"svg-fill-white"}
                width={20}
                height={20}
              />
            </span>
            <span className="button-text" style={{ paddingLeft: "8px" }}>
              {props.multiple
                ? tCms("file-chooseFiles")
                : tCms("file-chooseFile")}
            </span>
          </Button>
        </div>

        <div className="ms-2 my-auto remove-file-btn">
          {props.showRemoveBtn && selectedFiles.length > 0 ? (
            <IconButton
              aria-label={tCms("file-removeDownload")}
              onClick={onRemoveFile}
            >
              {<Clear color="error" sx={{ fontWeight: 600, fontSize: 28 }} />}
            </IconButton>
          ) : null}
        </div>
        <div className="file-input-preview ms-2 m-auto">
          {props.showPreview && selectedFiles.length > 0 ? (
            <>
              {isImage(selectedFiles[0]) ? (
                <img
                  key={selectedFiles[0].name}
                  alt="preview"
                  style={{
                    maxHeight: props.previewMaxHeight
                      ? props.previewMaxHeight
                      : "100px",
                  }}
                  height={getPreviewImgHeightAttributeValue()}
                  src={
                    selectedFiles[0].type.includes("image")
                      ? URL.createObjectURL(selectedFiles[0])
                      : "/file-placeholder.png"
                  }
                />
              ) : (
                <FilePlaceholderIcon
                  mimeType={selectedFiles[0].mimeType}
                  width={30}
                  height={30}
                  style={{}}
                />
              )}
            </>
          ) : null}
        </div>
      </div>
      <input
        ref={fileInput}
        type="file"
        hidden
        onChange={onChange}
        multiple={props.multiple}
      />
      {selectedFiles.length > 0 && props.showRemoveBtn ? (
        <>
          <br />
          <div className="d-flex">
            <div className="my-auto" style={{ wordBreak: "break-all" }}>
              {selectedFiles[0] ? selectedFiles[0].name : ""}
            </div>
          </div>
        </>
      ) : null}
      <style jsx global>{`
        .cms-file-input-btn {
          background-color: var(--pb-cms-color);
          color: white;
          height: 40px;
          font-size: 16px;
          text-transform: none;
          .button-text {
            display: none;
          }
          @media (min-width: ${props.showOnlyIconBelowWidth
              ? `${props.showOnlyIconBelowWidth}px`
              : `${globalConfig?.responsive?.breakpoints?.mobile}px`}) {
            .button-text {
              display: block;
            }
          }
        }

        .cms-file-input-btn.MuiButton-root:hover {
          background-color: #1565c0;
        }
      `}</style>
    </>
  );
};

export default PbFileInput;
