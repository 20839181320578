import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import {
  setEditViewAction,
  updateAttributeAction,
} from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import {
  lowerBreakpointDesktop,
  lowerBreakpointTablet,
  lowerBreakpointWqhd,
} from "@/utils/util";
import { Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import {
  menuItemStyle,
  menuStyle,
  typographyStyle,
} from "./cmsTopBarRightControls";
/**
 * CmsTopBarViewportControls renders the viewport switch for the cms user.
 * @param props - The component properties.
 * @returns A JSX element representing the viewportControls.
 */
const CmsTopBarViewportControls = (): JSX.Element => {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  const viewportPreview = useAppSelector(
    (state) => state.cmsGeneral.viewportPreview
  );
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [editViewPrevState, setEditViewPrevState] = React.useState<
    null | boolean
  >(null);

  const viewportDesktop = `${lowerBreakpointWqhd()}px`;
  const viewportTablet = `${lowerBreakpointDesktop() - 1}px`;
  const viewportMobile = `${lowerBreakpointTablet() - 1}px`;

  const isViewportSelected = (viewportValue: string) => {
    return viewportPreview === viewportValue;
  };

  const handleViewportSelect = (viewportValue: string | null) => {
    if (viewportPreview === viewportValue) {
      dispatch(
        updateAttributeAction({
          attributePath: "viewportPreview",
          value: null,
        })
      );
      if (editViewPrevState) {
        dispatch(setEditViewAction(true));
        setEditViewPrevState(null);
      }
    } else {
      if (editView && viewportPreview === null) {
        setEditViewPrevState(true);
      }
      dispatch(
        updateAttributeAction({
          attributePath: "viewportPreview",
          value: viewportValue,
        })
      );
      dispatch(setEditViewAction(false));
    }
  };

  return (
    <>
      <PbButton
        aria-label="viewport preview"
        aria-controls="menu-appbar-viewport-preview"
        aria-haspopup="true"
        className="cms-top-bar-btn"
        color="inherit"
        id="cms-viewport-preview-button"
        showOnlyIconBelowWidth={1450}
        startIcon={
          <PbIcon
            name="desktop-light"
            width={22}
            height={20}
            className="svg-fill-white"
          />
        }
        onClick={(event) => setAnchorEl(event.currentTarget)}
        title={tCms("topBar-Viewport")}
      >
        {tCms("topBar-Viewport")}
      </PbButton>
      <Menu
        sx={menuStyle}
        id="menu-appbar-viewport-control-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="pb-cms"
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          selected={isViewportSelected(viewportDesktop)}
          key={"viewport-desktop"}
          id="viewport-desktop"
          className={"cms-mui-paper"}
          onClick={() => {
            handleViewportSelect(viewportDesktop);
          }}
          sx={menuItemStyle}
        >
          <div className="d-flex">
            <span
              style={{
                marginTop: "-2px",
                marginRight: "7px",
              }}
              title={tCms("topBar-Viewport-desktop")}
            >
              <PbIcon
                name="desktop-light"
                width={22}
                height={20}
                className="svg-fill-white"
              />
            </span>
            <Typography sx={typographyStyle} textAlign="center">
              {tCms("topBar-Viewport-desktop")}
            </Typography>
          </div>
        </MenuItem>
        <MenuItem
          selected={isViewportSelected(viewportTablet)}
          key={"viewport-tablet"}
          id="viewport-tablet"
          className={"cms-mui-paper"}
          onClick={() => {
            handleViewportSelect(viewportTablet);
          }}
          sx={menuItemStyle}
        >
          <div className="d-flex">
            <span
              style={{
                marginTop: "-2px",
                marginRight: "7px",
              }}
              title={tCms("topBar-Viewport-tablet")}
            >
              <PbIcon
                name="tablet-screen-button-light"
                width={22}
                height={20}
                className="svg-fill-white"
              />
            </span>
            <Typography sx={typographyStyle} textAlign="center">
              {tCms("topBar-Viewport-tablet")}
            </Typography>
          </div>
        </MenuItem>
        <MenuItem
          selected={isViewportSelected(viewportMobile)}
          key={"viewport-mobile"}
          id="viewport-mobile"
          className={"cms-mui-paper active"}
          onClick={() => {
            handleViewportSelect(viewportMobile);
          }}
          sx={menuItemStyle}
        >
          <div className="d-flex">
            <span
              style={{
                marginTop: "-2px",
                marginRight: "7px",
              }}
              title={tCms("topBar-Viewport-mobile")}
            >
              <PbIcon
                name="mobile-notch-light"
                width={22}
                height={20}
                className="svg-fill-white"
              />
            </span>
            <Typography sx={typographyStyle} textAlign="center">
              {tCms("topBar-Viewport-mobile")}
            </Typography>
          </div>
        </MenuItem>
      </Menu>
    </>
  );
};

export default CmsTopBarViewportControls;
