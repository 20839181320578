import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";

interface CmsAttributeBooleanProps {
  name: string;
  value: boolean;
  onChange: (name: string, checked: boolean) => void;
  isNonLocalizedDisabled?: boolean;
  idPrefix: string;
}

export default function CmsAttributeBoolean(
  props: Readonly<CmsAttributeBooleanProps>
) {
  return (
    <PbCheckBox
      id={props.idPrefix + "-" + props.name}
      withoutColon
      value={props.value}
      isSlider={true}
      isDisabled={props.isNonLocalizedDisabled}
      onChange={() => {
        props.onChange(props.name, !props.value);
      }}
      fullHeight
    />
  );
}
