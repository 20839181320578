import CmsMediaManagerFile from "@/components/cms/cmsMediaManager/cmsMediaManagerFile";
import CmsMediaManagerFolder from "@/components/cms/cmsMediaManager/cmsMediaManagerFolder";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import {
  mediaManagerModalSelectFileAction,
  mediaManagerModalSelectFolderAction,
  updateAttributeAction,
} from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { cmsMediaManagerMoveFileIntoFolderThunk } from "@/store/slices/cmsGeneral/cmsMediaManagerThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { StrapiUploadFile, StrapiUploadFolder } from "@/types/strapi";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import { useCallback, useEffect, useMemo, useRef } from "react";

interface CmsMediaManagerContentListProps {
  onFolderDoubleClick?: (folder: StrapiUploadFolder) => void;
  onFileDoubleClick?: (file: StrapiUploadFile) => void;
}

const isFolder = (item: StrapiUploadFolder | StrapiUploadFile) => {
  return (item as StrapiUploadFolder)?.pathId !== undefined;
};

export default function CmsMediaManagerItemGrid(
  props: CmsMediaManagerContentListProps
) {
  const dispatch = useAppDispatch();
  const tCms = useCmsTranslation();
  const itemGridRef = useRef<HTMLDivElement>(null);
  const currentItem = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.currentItem
  );
  const selectedIndex = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.content.selectItem
  );
  const { files, folders, parents } = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.content
  );
  const rootFolder = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.rootFolder
  );
  const parentFolder: StrapiUploadFolder[] = useMemo(() => {
    if (parents && parents.length > 1) {
      return [{ ...parents[parents.length - 2], isParentFolder: true }];
    } else if (parents && parents.length === 1) {
      return [{ ...rootFolder!, isParentFolder: true }];
    } else {
      return [];
    }
  }, [parents, rootFolder]);

  const dndContextActivationSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 5,
    },
  });

  const gridFocusIndex = useRef<number>(0);
  const combinedItems = useMemo(
    () => [...parentFolder, ...folders, ...files],
    [parentFolder, folders, files]
  );

  const selectGridItemByIndex = useCallback(
    (gridIndex: number) => {
      const selectedElement = combinedItems[gridIndex];
      if ("pathId" in selectedElement) {
        dispatch(mediaManagerModalSelectFolderAction(selectedElement));
      } else {
        dispatch(mediaManagerModalSelectFileAction(selectedElement));
      }

      const selectedItemElement = document.getElementById(`item-${gridIndex}`);
      if (selectedItemElement) {
        selectedItemElement.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "nearest",
        });
      }
    },
    [dispatch, combinedItems]
  );

  useEffect(() => {
    if (selectedIndex) {
      let searchItem: StrapiUploadFile | StrapiUploadFolder | undefined;
      if ("pathId" in selectedIndex) {
        searchItem = folders.find((folder) => folder.id === selectedIndex.id);
      } else {
        searchItem = files.find((file) => file.id === selectedIndex.id);
      }
      if (searchItem) {
        const selectIndexAt = combinedItems.findIndex(
          (item) => item === searchItem
        );
        gridFocusIndex.current = selectIndexAt;
        selectGridItemByIndex(selectIndexAt);
      }
    }
  }, [selectedIndex, folders, files, combinedItems, selectGridItemByIndex]);

  const onFolderDoubleClickEvent = () => {
    gridFocusIndex.current = 0;
    props.onFolderDoubleClick &&
      props.onFolderDoubleClick(currentItem as StrapiUploadFolder);
  };

  const onFileDoubleClickEvent = () => {
    gridFocusIndex.current = 0;
    props.onFileDoubleClick &&
      props.onFileDoubleClick(currentItem as StrapiUploadFile);
  };

  const handleKeyboardNavigation = (event: any) => {
    if (combinedItems.length === 0 || !event) {
      return;
    }

    if (event.key === "Tab") {
      dispatch(
        updateAttributeAction({
          attributePath: "mediaManagerModal.focus",
          value: false,
        })
      );
      return;
    }

    event.preventDefault();

    if (event.key === "Enter") {
      return onKeyEnter();
    }

    if (
      ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(event.key)
    ) {
      return onKeyArrowNavigation(event);
    }
  };
  const onKeyEnter = () => {
    if (currentItem && isFolder(currentItem)) {
      onFolderDoubleClickEvent();
    }
    return;
  };
  const onKeyArrowNavigation = (event: any) => {
    const columnsAmount = () => {
      return getComputedStyle(itemGridRef.current!).gridTemplateColumns.split(
        " "
      ).length;
    };

    if (event.key === "ArrowUp" && gridFocusIndex.current >= columnsAmount()) {
      gridFocusIndex.current -= columnsAmount();
    } else if (
      event.key === "ArrowDown" &&
      gridFocusIndex.current + columnsAmount() < combinedItems.length
    ) {
      gridFocusIndex.current += columnsAmount();
    } else if (event.key === "ArrowLeft" && gridFocusIndex.current > 0) {
      gridFocusIndex.current--;
    } else if (
      event.key === "ArrowRight" &&
      gridFocusIndex.current < combinedItems.length - 1
    ) {
      gridFocusIndex.current++;
    }

    selectGridItemByIndex(gridFocusIndex.current);
    return;
  };

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;
    if (active?.data?.current && over?.data?.current) {
      try {
        await dispatch(
          cmsMediaManagerMoveFileIntoFolderThunk(
            active.data.current.file.id,
            over.data.current.folder.id
          )
        );
        createToastSuccess(tCms("managedFileMoveIntoFolderSuccess"));
      } catch (e) {
        createToastError(tCms("genericError"));
      }
    }
  };

  if (combinedItems.length === 0) {
    return <div style={{ display: "flex", justifyContent: "center" }}></div>;
  }

  return (
    <>
      <div
        ref={itemGridRef}
        className="media-manager-item-grid"
        tabIndex={0}
        onKeyDown={handleKeyboardNavigation}
        onFocus={() => {
          if (!currentItem) {
            gridFocusIndex.current = 0;
            selectGridItemByIndex(gridFocusIndex.current);
          }
          dispatch(
            updateAttributeAction({
              attributePath: "mediaManagerModal.focus",
              value: true,
            })
          );
        }}
      >
        <DndContext
          onDragEnd={handleDragEnd}
          sensors={[dndContextActivationSensor]}
          autoScroll
        >
          {combinedItems?.map((item, index) => {
            if (isFolder(item)) {
              return (
                <div
                  aria-hidden="true"
                  key={index}
                  onDoubleClick={onFolderDoubleClickEvent}
                  onClick={() => (gridFocusIndex.current = index)}
                >
                  <CmsMediaManagerFolder
                    id={`item-${index}`}
                    folder={item as StrapiUploadFolder}
                  />
                </div>
              );
            }
            return (
              <div
                aria-hidden="true"
                key={index}
                onDoubleClick={onFileDoubleClickEvent}
                onClick={() => (gridFocusIndex.current = index)}
              >
                <CmsMediaManagerFile
                  id={`item-${index}`}
                  key={index}
                  file={item as StrapiUploadFile}
                />
              </div>
            );
          })}
        </DndContext>
      </div>
      <style jsx>{`
        .media-manager-item-grid {
          outline: none;
          display: grid;
          grid-gap: 12px;
          grid-template-columns: repeat(auto-fit, minmax(138px, 138px));
          justify-content: start;
          padding: 10px;
          overflow: hidden;
        }
      `}</style>
    </>
  );
}
