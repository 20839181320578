import CmsAttributes from "@/components/cms/cmsAttributes/cmsAttributes";
import CmsPageSeoSettings from "@/components/cms/cmsPage/cmsPageSeoSettings";
import CmsPageSettings from "@/components/cms/cmsPage/cmsPageSettings";
import CmsTabs from "@/components/cms/cmsTabs/cmsTabs";
import PbGenericModal from "@/components/genericModal/pbGenericModal";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { getPageTypeNameLocalized } from "@/services/cmsPageTypeService/cmsPageTypeService";
import {
  initEditDataAction,
  resetEditDataAction,
  updateAttributeAction,
} from "@/store/slices/cmsEdit/cmsEditSlice";
import { cmsCreatePageThunk } from "@/store/slices/cmsEdit/cmsPageThunks";
import { hideCreatePageModalAction } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { showLoadingOverlayAction } from "@/store/slices/general/generalSlice";
import { redirectThunk } from "@/store/slices/general/generalThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { isExcludedByPageType } from "@/utils/util";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import CmsProperties from "../cmsProperties/cmsProperties";

type PageSettingsTab = {
  label: string;
  tabName: string;
  value: string;
  data: any;
};

export default function CmsPageCreateModal() {
  const tCms = useCmsTranslation();
  const showCreatePageModal = useAppSelector(
    (state) => state.cmsGeneral.createPageModal.showCreatePageModal
  );
  const selectedPageType = useAppSelector(
    (state) => state.cmsGeneral.createPageModal.selectedPageType
  );
  const editData = useAppSelector((state) => state.cmsEdit.data);
  const pageAttributes = useAppSelector(
    (state) => state.cmsGeneral.pageAttributes
  );
  const [tabValue, setTabValue] = useState<string>("0");

  const dispatch = useAppDispatch();
  const router = useRouter();

  useEffect(() => {
    if (showCreatePageModal) {
      dispatch(
        initEditDataAction({
          name: "",
          hasNavigation: true,
          hasFooter: true,
          redirectEnabled: false,
          redirectUrl: "",
          type: selectedPageType,
          config: {
            properties: selectedPageType?.newPropertyValuesStructure ?? null,
          },
          attributes: pageAttributes,
        })
      );
    } else {
      dispatch(resetEditDataAction());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCreatePageModal, dispatch, selectedPageType]);
  const handleCreatePage = async () => {
    try {
      const createdPage = await dispatch(cmsCreatePageThunk());
      createToastSuccess(tCms("pageCreationSuccess"));
      dispatch(showLoadingOverlayAction(tCms("creatingPage")));
      dispatch(hideCreatePageModalAction());
      dispatch(
        redirectThunk(
          () =>
            router.push("/" + createdPage.url + "?edit=true", undefined, {
              locale: createdPage.locale,
            }),
          "/" + createdPage.url + "?edit=true"
        )
      );
    } catch (error: any) {
      if (error?.message === "PAGE_NAME_ALREADY_EXISTS") {
        createToastError(tCms("pageNameAlreadyExists"));
      } else {
        createToastError(tCms("pageCreationError"));
      }
    }
  };

  const getCreatePageDialogTitle = () => {
    let dialogTitle = tCms("page-createPage");
    const currentPageType = editData?.type ?? null;

    if (currentPageType) {
      const pageTypeName = getPageTypeNameLocalized(
        currentPageType,
        global.envVar("PABU_PUBLIC_CMS_USER_LANGUAGE")!
      );
      if (pageTypeName) {
        dialogTitle = dialogTitle + ` (${pageTypeName})`;
      }
    }
    return dialogTitle;
  };

  const pagePropertiesExists = () => {
    if (!selectedPageType?.config?.properties) {
      return false;
    }
    if (selectedPageType?.config?.properties.length === 0) {
      return false;
    }
    return true;
  };

  const handleChange = (name: string, value: any) => {
    dispatch(
      updateAttributeAction({
        attributePath: name,
        value: value,
      })
    );
  };

  function pageSettingsTabs(): PageSettingsTab[] {
    if (!editData || !editData.attributes) {
      return [];
    }
    const attributesTab: Record<string, any> = {};
    for (const [key, attribute] of Object.entries(pageAttributes)) {
      const tab = (attribute as { tab?: string }).tab ?? "extension";
      if (
        tab === "extension" &&
        isExcludedByPageType(editData, key, attribute)
      ) {
        continue;
      }
      if (!attributesTab[tab]) {
        attributesTab[tab] = { ...editData, attributes: {} };
      }
      attributesTab[tab].attributes[key] = attribute;
    }
    return Object.entries(attributesTab).map(
      ([key, data], index) =>
        ({
          label: key,
          tabName: key,
          value: index + "",
          data: data,
        } as PageSettingsTab)
    );
  }

  function getPageSettingsTabByLabel(
    tabLabel: string,
    pageSettingsTabs: PageSettingsTab[]
  ) {
    return pageSettingsTabs.find((tab) => tab.label === tabLabel);
  }

  const customTabs = ["page-pageInformations", "seo"];

  return (
    <PbGenericModal
      className="cms-modal with-tabs cms-page-create-modal"
      open={showCreatePageModal}
      title={getCreatePageDialogTitle()}
      muiDialogProps={{
        fullWidth: true,
        maxWidth: "md",
      }}
      onConfirmClick={handleCreatePage}
      onCancelClick={() => {
        setTabValue("0");
        dispatch(hideCreatePageModalAction());
      }}
      muiDialogContentSxProps={{
        padding: 0,
      }}
    >
      <CmsTabs>
        <TabContext value={tabValue}>
          <TabList
            className="tab-list"
            aria-label={tCms("selectTab")}
            onChange={(event: any, newValue: string) => {
              setTabValue(newValue);
            }}
          >
            <Tab label={tCms("page-pageInformations")} value="0" />
            <Tab label={tCms("seo")} value="1" />
            {pagePropertiesExists() && (
              <Tab label={tCms("properties")} value="2" />
            )}
            {pageSettingsTabs().map((tab) => {
              if (!customTabs.includes(tab.label)) {
                return (
                  <Tab
                    key={tab.value + 1}
                    label={tCms(tab.label)}
                    value={tab.value + 1}
                  />
                );
              }
            })}
          </TabList>
          <TabPanel value="0">
            <CmsPageSettings
              data={
                getPageSettingsTabByLabel(
                  "page-pageInformations",
                  pageSettingsTabs()
                )?.data ?? {}
              }
              onChange={handleChange}
            />
          </TabPanel>
          <TabPanel value="1">
            <CmsPageSeoSettings
              data={
                getPageSettingsTabByLabel("seo", pageSettingsTabs())?.data ?? {}
              }
              onChange={handleChange}
            />
          </TabPanel>
          <TabPanel value="2">
            {pagePropertiesExists() && (
              <CmsProperties
                accordionDefaultExpanded
                propertyAttributeGroups={
                  selectedPageType?.config.properties ?? []
                }
                propertyValues={editData?.config?.properties}
                onChange={(attributeGroupName, attributeName, value) => {
                  dispatch(
                    updateAttributeAction({
                      attributePath: `config.properties.${attributeGroupName}.${attributeName}`,
                      value: value,
                    })
                  );
                }}
              />
            )}
          </TabPanel>
          {pageSettingsTabs().map((tab) => {
            if (!customTabs.includes(tab.label)) {
              return (
                <TabPanel key={tab.value + 1} value={tab.value + 1}>
                  <CmsAttributes
                    data={tab.data}
                    attributes={tab.data.attributes}
                    onChange={handleChange}
                  />
                </TabPanel>
              );
            }
          })}
        </TabContext>
      </CmsTabs>
    </PbGenericModal>
  );
}
