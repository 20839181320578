import CmsAttributes from "@/components/cms/cmsAttributes/cmsAttributes";
import CmsPageSeoSettings from "@/components/cms/cmsPage/cmsPageSeoSettings";
import CmsPageSettings from "@/components/cms/cmsPage/cmsPageSettings";
import CmsTabs from "@/components/cms/cmsTabs/cmsTabs";
import PbButton from "@/components/input/pbButton/pbButton";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import { getPageTypeNameLocalized } from "@/services/cmsPageTypeService/cmsPageTypeService";
import { updateAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import {
  cmsPageDeleteThunk,
  cmsSaveDraftPageContentThunk,
  cmsUpdatePageSettingsThunk,
} from "@/store/slices/cmsEdit/cmsPageThunks";
import { refreshRenderKey } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import {
  disableBeforeunloadAction,
  showLoadingOverlayAction,
} from "@/store/slices/general/generalSlice";
import { redirectThunk } from "@/store/slices/general/generalThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { isLocaleDefaultLocale } from "@/utils/localizationUtil";
import { isPageUrlRootUrl } from "@/utils/urlUtil";
import { deepImmutableCopy, isExcludedByPageType } from "@/utils/util";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import CloseIcon from "@mui/icons-material/Close";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import CmsProperties from "../cmsProperties/cmsProperties";

interface CmsPageSettingsModalProps {
  open: boolean;
  onClose: () => void;
}

type PageSettingsTab = {
  label: string;
  tabName: string;
  value: string;
  data: any;
};

export default function CmsPageSettingsModal(props: CmsPageSettingsModalProps) {
  const tCms = useCmsTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const draftPage = useAppSelector((state) => state.cmsEdit.data?.draftPage);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [tabValue, setTabValue] = useState<string>("0");
  const renderKey = useAppSelector((state) => state.cmsGeneral.renderKey);
  const pageName = useAppSelector(
    (state) => state.cmsEdit.data?.draftPage.name
  );

  let originDraftPageSettings = useRef<any>(null);

  const notDeletable =
    isPageUrlRootUrl(draftPage?.url) && isLocaleDefaultLocale(router.locale!);

  useEffect(() => {
    if (props.open) {
      if (originDraftPageSettings.current === null) {
        originDraftPageSettings.current = deepImmutableCopy(draftPage);
      }
    } else {
      originDraftPageSettings.current = null;
    }
  }, [props.open, draftPage]);

  const { showConfirmModal } = useConfirmModal(async () => {
    try {
      dispatch(showLoadingOverlayAction(tCms("deletingPage")));
      await dispatch(cmsPageDeleteThunk());
      createToastSuccess(tCms("pageDeleteSuccess"));
      props.onClose();
      dispatch(redirectThunk(() => router.push("/"), "/"));
    } catch (error) {
      createToastError(tCms("pageDeleteError"));
    }
  });

  const handleModalCloseEvent = () => {
    setTabValue("0");
    props.onClose();
  };

  const handleSaveEvent = async () => {
    try {
      dispatch(showLoadingOverlayAction(tCms("updatingPage")));
      await dispatch(cmsSaveDraftPageContentThunk());
      createToastSuccess(tCms("pageSaveSuccess"));
      const updatePageSettings = await dispatch(cmsUpdatePageSettingsThunk());
      console.log("updatedPageSettings", updatePageSettings);
      createToastSuccess(tCms("pageUpdateSuccess"));
      props.onClose();
      dispatch(disableBeforeunloadAction());
      dispatch(
        redirectThunk(
          () => router.push("/" + updatePageSettings.url),
          "/" + updatePageSettings.url
        )
      );
    } catch (error: any) {
      if (error?.message === "PAGE_NAME_ALREADY_EXISTS") {
        createToastError(tCms("pageNameAlreadyExists"));
      } else {
        createToastError(tCms("pageUpdateError"));
      }
    }
  };

  const handleResetEvent = () => {
    dispatch(
      updateAttributeAction({
        attributePath: `draftPage`,
        value: originDraftPageSettings.current,
      })
    );
    dispatch(refreshRenderKey());
  };

  const handleDeleteEvent = async () => {
    if (notDeletable) {
      return;
    }

    showConfirmModal({
      title: tCms("deletePage"),
      content: tCms("confirmingDeletePage", {
        name: pageName,
      }),
      acceptBtnText: tCms("delete"),
      denyBtnText: tCms("cancel"),
      icon: "triangle-exclamation-light",
    });
  };

  const pagePropertiesExists = () => {
    if (!draftPage) {
      return false;
    }
    if (!draftPage?.type?.config?.properties) {
      return false;
    }
    if (draftPage?.type?.config?.properties.length === 0) {
      return false;
    }
    if (!draftPage?.config || !draftPage?.config?.properties) {
      return false;
    }
    return true;
  };

  const handleChange = (name: string, value: any) => {
    dispatch(
      updateAttributeAction({
        attributePath: `draftPage.${name}`,
        value: value,
      })
    );
  };

  if (!draftPage) {
    return <></>;
  }

  function pageSettingsTabs(): PageSettingsTab[] {
    const attributesTab: Record<string, any> = {};
    for (const [key, attribute] of Object.entries(draftPage.attributes)) {
      const tab = (attribute as { tab?: string }).tab ?? "extension";
      if (
        tab === "extension" &&
        isExcludedByPageType(draftPage, key, attribute)
      ) {
        continue;
      }
      if (!attributesTab[tab]) {
        attributesTab[tab] = { ...draftPage, attributes: {} };
      }
      attributesTab[tab].attributes[key] = attribute;
    }
    return Object.entries(attributesTab).map(
      ([key, data], index) =>
        ({
          label: key,
          tabName: key,
          value: index + "",
          data: data,
        } as PageSettingsTab)
    );
  }

  function getPageSettingsTabByLabel(
    tabLabel: string,
    pageSettingsTabs: PageSettingsTab[]
  ) {
    return pageSettingsTabs.find((tab) => tab.label === tabLabel);
  }

  const customTabs = ["page-pageInformations", "seo"];

  return (
    <Dialog
      className="cms-page-settings-modal cms-modal with-tabs"
      open={props.open}
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle className="cms-modal-title">
        {getPageTypeNameLocalized(
          draftPage.type,
          global.envVar("PABU_PUBLIC_CMS_USER_LANGUAGE")!
        ) ?? tCms("page-editPage")}
        {" " + tCms("settings")}: {draftPage?.name}
      </DialogTitle>
      <IconButton
        className="modal-close"
        aria-label="close"
        onClick={handleModalCloseEvent}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        key={renderKey}
        sx={{
          padding: 0,
        }}
      >
        <CmsTabs>
          <TabContext value={tabValue}>
            <TabList
              className="tab-list"
              aria-label={tCms("selectTab")}
              onChange={(_: any, newValue: string) => {
                setTabValue(newValue);
              }}
            >
              <Tab label={tCms("page-pageInformations")} value="0" />
              <Tab label={tCms("seo")} value="1" />
              {pagePropertiesExists() && (
                <Tab label={tCms("properties")} value="2" />
              )}
              {pageSettingsTabs().map((tab) => {
                if (!customTabs.includes(tab.label)) {
                  return (
                    <Tab
                      key={tab.value + 1}
                      label={tCms(tab.label)}
                      value={tab.value + 1}
                    />
                  );
                }
              })}
            </TabList>
            <TabPanel value="0">
              <CmsPageSettings
                data={
                  getPageSettingsTabByLabel(
                    "page-pageInformations",
                    pageSettingsTabs()
                  )?.data ?? {}
                }
                onChange={handleChange}
              />
            </TabPanel>
            <TabPanel value="1">
              <CmsPageSeoSettings
                data={
                  getPageSettingsTabByLabel("seo", pageSettingsTabs())?.data ??
                  {}
                }
                onChange={handleChange}
              />
            </TabPanel>
            {pagePropertiesExists() && (
              <TabPanel value="2">
                <CmsProperties
                  accordionDefaultExpanded
                  propertyAttributeGroups={
                    draftPage.type.config.properties ?? []
                  }
                  propertyValues={draftPage?.config?.properties}
                  onChange={(attributeGroupName, attributeName, value) => {
                    dispatch(
                      updateAttributeAction({
                        attributePath:
                          `draftPage.config.properties.` +
                          `${attributeGroupName}.${attributeName}`,
                        value: value,
                      })
                    );
                  }}
                />
              </TabPanel>
            )}
            {pageSettingsTabs().map((tab) => {
              if (!customTabs.includes(tab.label)) {
                return (
                  <TabPanel key={tab.value + 1} value={tab.value + 1}>
                    <CmsAttributes
                      data={tab.data}
                      attributes={tab.data.attributes}
                      onChange={handleChange}
                    />
                  </TabPanel>
                );
              }
            })}
          </TabContext>
        </CmsTabs>
      </DialogContent>
      <DialogActions>
        <PbButton
          id={"cms-page-settings-modal-reset-btn"}
          color="secondary"
          variant="outlined"
          onClick={handleResetEvent}
        >
          {tCms("reset")}
        </PbButton>
        <PbButton
          id={"cms-page-settings-modal-delete-btn"}
          color="error"
          onClick={handleDeleteEvent}
          disabled={notDeletable}
        >
          {tCms("deletePage")}
        </PbButton>
        <PbButton
          id={"cms-page-settings-modal-cancel-btn"}
          color="secondary"
          variant="outlined"
          onClick={handleModalCloseEvent}
        >
          {tCms("cancel")}
        </PbButton>
        <PbButton
          id={"cms-page-settings-modal-accept-btn"}
          color="primary"
          onClick={handleSaveEvent}
        >
          {tCms("acceptAndSave")}
        </PbButton>
      </DialogActions>
    </Dialog>
  );
}
