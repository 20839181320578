/**
 * This function provides the internal URL for the Strapi Docker and can solely be accessed from the server side.
 * If you want to access a strapi file from the client use getStrapiURLClientSide or getNextJsApiURL directly
 *
 * since v4 all controllers are prefixed with /api, /uploads and /assets are not
 *
 * @param {*} path
 * @returns
 */
export const getStrapiURL = (path: string): string => {
  if (
    typeof path === "string" &&
    (path.startsWith("/uploads/") || path.startsWith("/assets/"))
  ) {
    return `${global.envVar("PABU_PUBLIC_STRAPI_API_URL")}${path}`;
  }
  return `${global.envVar("PABU_PUBLIC_STRAPI_API_URL")}/api${path}`;
};

export const getStrapiPrivateUrl = (path: string) => {
  return `${global.envVar("PABU_PUBLIC_STRAPI_API_URL")}${path}`;
};

/**
 * This function returns a the URL to a file that is placed in
 * strapi /uploads or /assets folder
 *
 * This allows strapi to be only reachable through the API gateway (with some more configuration)
 *
 * @param {*} path
 * @returns
 */
export const getStrapiURLClientSide = (path: string): string => {
  if (typeof path === "string") {
    if (path.startsWith("/uploads/") || path.startsWith("/assets/")) {
      return getNextJsApiURL(path);
    }
    if (
      global.envVar("PABU_PUBLIC_STRAPI_MEDIA_URL") &&
      path.startsWith(global.envVar("PABU_PUBLIC_STRAPI_MEDIA_URL")!)
    ) {
      return `${global.envVar(
        "PABU_PUBLIC_NEXTJS_URL"
      )}/api/media${path.replaceAll(
        global.envVar("PABU_PUBLIC_STRAPI_MEDIA_URL")!,
        ""
      )}`;
    }
  }
  global.log.debug(
    "[getStrapiURLClientSide] you are trying to call strapi outside of /uploads or /assets from the client!"
  );
  return "";
};

export const getNextJsApiURL = (path: string): string => {
  return `${global.envVar("PABU_PUBLIC_NEXTJS_URL")}/api${path}`;
};
