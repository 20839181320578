import CmsMediaManagerBreadcrumbs from "@/components/cms/cmsMediaManager/cmsMediaManagerBreadcrumbs";
import CmsMediaManagerDetailActionButtons from "@/components/cms/cmsMediaManager/cmsMediaManagerDetailActionButtons";
import CmsMediaManagerFileDetails from "@/components/cms/cmsMediaManager/cmsMediaManagerFileDetails";
import CmsMediaManagerFilter from "@/components/cms/cmsMediaManager/cmsMediaManagerFilter";
import CmsMediaManagerFolderDetails from "@/components/cms/cmsMediaManager/cmsMediaManagerFolderDetails";
import CmsMediaManagerItemGrid from "@/components/cms/cmsMediaManager/cmsMediaManagerItemGrid";
import CmsMediaManagerNewFile from "@/components/cms/cmsMediaManager/cmsMediaManagerNewFile";
import PbButton from "@/components/input/pbButton/pbButton";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import {
  itemsByPath,
  searchFile,
} from "@/services/cmsMediaManager/cmsMediaManagerService";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import {
  hideMediaManagerModalAction,
  mediaManagerModalNewFileAction,
  mediaManagerModalSetCurrentFolderAction,
  mediaManagerModalSetRootFolderAction,
  updateAttributeAction,
} from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import {
  cancelClipboardAction,
  setClipboardAction,
  showLoadingOverlayAction,
} from "@/store/slices/general/generalSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { StrapiUploadFile, StrapiUploadFolder } from "@/types/strapi";
import { MEDIA_TYPE_DOCUMENT } from "@/utils/constants";
import { triggerGetServerSideProps } from "@/utils/util";
import CloseIcon from "@mui/icons-material/Close";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

interface CmsMediaManagerModalProps {}

export default function CmsMediaManagerModal(props: CmsMediaManagerModalProps) {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const open = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.open
  );
  const itemSelection = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.itemSelection
  );
  const currentItem = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.currentItem
  );
  const currentFolder = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.currentFolder
  );
  const parents = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.content.parents
  );
  const detailView = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.detailView
  );
  const mediaTypes = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.mediaTypes
  );
  const triggerRefreshOnClose = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.triggerRefreshOnClose
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [filterCategory, setFilterCategory] = useState<string>("");
  const [newFile, setNewFile] = useState<any>(null);

  useEffect(() => {
    if (detailView !== "NEW_FILE") {
      if (newFile !== null) {
        setNewFile(null);
      }
    }
  }, [detailView, newFile]);

  const updatePath = (folder: StrapiUploadFolder | undefined) => {
    if (!folder) {
      dispatch(mediaManagerModalSetRootFolderAction());
      return;
    }
    dispatch(mediaManagerModalSetCurrentFolderAction(folder));
  };

  const updateContent = (
    files: StrapiUploadFile[],
    folders: StrapiUploadFolder[],
    parents: StrapiUploadFolder[]
  ) => {
    dispatch(
      updateAttributeAction({
        attributePath: "mediaManagerModal.content.files",
        value: files,
      })
    );
    dispatch(
      updateAttributeAction({
        attributePath: "mediaManagerModal.content.folders",
        value: folders,
      })
    );
    dispatch(
      updateAttributeAction({
        attributePath: "mediaManagerModal.content.parents",
        value: parents,
      })
    );
  };

  const handleModalConfirm = () => {
    if (!itemSelection) {
      return;
    }
    if (currentItem && "pathId" in currentItem) {
      return;
    }
    dispatch(setClipboardAction(currentItem as StrapiUploadFile));
    dispatch(hideMediaManagerModalAction());
  };

  const handleModalCancel = () => {
    dispatch(cancelClipboardAction());
    dispatch(hideMediaManagerModalAction());
    if (triggerRefreshOnClose) {
      dispatch(showLoadingOverlayAction(tCms("managedFileModal-file-refresh")));
      triggerGetServerSideProps(router);
    }
  };

  const fetchFiles = async () => {
    const itemsResponse = await itemsByPath(
      currentFolder?.path ?? "/",
      currentFolder?.id ?? undefined
    );
    const filteredItemsResponse = {
      ...itemsResponse,
      files: filterByAllowedCategories(
        itemsResponse.files,
        filterCategory === "" ? mediaTypes : [filterCategory]
      ).sort((a: StrapiUploadFile, b: StrapiUploadFile) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      }),
    };
    updateContent(
      filteredItemsResponse.files,
      filteredItemsResponse.folders,
      filteredItemsResponse.parents
    );
  };

  const searchFiles = async () => {
    const allFiles = await searchFile(searchTerm);
    const onlyAllowedFiles = filterByAllowedCategories(allFiles, mediaTypes);
    const filteredFiles = filterByCategory(onlyAllowedFiles, filterCategory);
    updateContent(filteredFiles, [], []);
  };

  /**
   * returns only files that match the given filter category
   */
  const filterByCategory = (
    files: StrapiUploadFile[],
    filterCategory: string
  ) => {
    return files.filter((file) => {
      if (filterCategory === MEDIA_TYPE_DOCUMENT) {
        return (
          file.mime.startsWith("application") || file.mime.startsWith("text")
        );
      }
      return file.mime.includes(filterCategory);
    });
  };

  /**
   * returns only files that match the given categories list
   */
  const filterByAllowedCategories = (
    files: StrapiUploadFile[],
    categories: string[]
  ) => {
    return files.filter((file: StrapiUploadFile) =>
      categories.some((category) => {
        if (category === MEDIA_TYPE_DOCUMENT) {
          return (
            file.mime.startsWith("application") || file.mime.startsWith("text")
          );
        }
        return file.mime.includes(category);
      })
    );
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      if (searchTerm) {
        searchFiles().then(() => setLoading(false));
      } else {
        fetchFiles().then(() => setLoading(false));
      }
    } else {
      setSearchTerm("");
      setFilterCategory("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFolder, open, searchTerm, filterCategory]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Dialog
        className="cms-modal cms-media-manager-modal"
        fullScreen={fullScreen}
        open={open}
        fullWidth={true}
        PaperProps={{
          sx: { maxWidth: "100%", height: "100%" },
        }}
      >
        <DialogTitle className="cms-modal-title">
          {tCms("managedFile-title")}
        </DialogTitle>
        <IconButton
          className="modal-close"
          aria-label="close"
          onClick={handleModalCancel}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div className="media-manager-header-container">
            <div className="media-manager-header-filter">
              <CmsMediaManagerFilter
                onSearchChange={setSearchTerm}
                onMimeTypeChange={setFilterCategory}
              />
              <div className="media-manager-header-action-buttons">
                <CmsMediaManagerDetailActionButtons
                  onNewFileChange={(e, file) => {
                    setNewFile(file);
                    dispatch(mediaManagerModalNewFileAction());
                  }}
                />
              </div>
            </div>
            <CmsMediaManagerBreadcrumbs
              parents={parents}
              onSelectEvent={updatePath}
            />
          </div>
          <div className="media-manager-content-container">
            <div className="media-manager-content-grid">
              {loading ? (
                <div className="loading-spinner">
                  <CircularProgress size={30} />
                </div>
              ) : (
                <CmsMediaManagerItemGrid
                  onFolderDoubleClick={updatePath}
                  onFileDoubleClick={handleModalConfirm}
                ></CmsMediaManagerItemGrid>
              )}
            </div>
            <div className="media-manager-content-details">
              {(() => {
                switch (detailView) {
                  case "NEW_FOLDER":
                  case "EDIT_FOLDER":
                    return <CmsMediaManagerFolderDetails />;
                  case "EDIT_FILE":
                    return <CmsMediaManagerFileDetails />;
                  case "NEW_FILE":
                    return <CmsMediaManagerNewFile newFile={newFile} />;
                  default:
                    return <></>;
                }
              })()}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <PbButton
            id="media-manager-close-button"
            color="secondary"
            variant="outlined"
            autoFocus
            onClick={handleModalCancel}
          >
            {tCms("close")}
          </PbButton>
          {itemSelection && (
            <PbButton
              id="media-manager-confirm-button"
              variant="contained"
              disabled={!currentItem?.id}
              onClick={handleModalConfirm}
            >
              {tCms("confirm-selection")}
            </PbButton>
          )}
        </DialogActions>
      </Dialog>
      <style jsx>{`
        .loading-spinner {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          height: 200px;
        }
        .media-manager-header-container {
          display: flex;
          flex-direction: column;
        }
        .media-manager-header-filter {
          display: flex;
          justify-content: space-between;
        }
        .media-manager-header-action-buttons {
          display: block;
        }
        .media-manager-content-container {
          display: flex;
          max-height: calc(100vh - 300px);
        }
        .media-manager-content-grid {
          flex: 0.7;
          overflow: auto;
        }
        .media-manager-content-details {
          display: flex;
          flex: 0.3;
          overflow: auto;
          background-color: rgb(246, 247, 247);
        }

        @media screen and (max-width: ${globalConfig?.responsive?.breakpoints
            ?.mobile}px) {
          .media-manager-content-details {
            display: none;
          }
          .media-manager-content-grid {
            flex: 1;
          }
          .media-manager-header-action-buttons {
            display: none;
          }
          .media-manager-content-container {
            max-height: calc(100% - 130px);
          }
        }
      `}</style>
    </>
  );
}
