import PbRichTextEditor from "@/components/input/pbRichTextEditor/pbRichTextEditor";
import useCesStrRichText from "@/hooks/useCesStrRichText";

interface CmsAttributeRichTextProps {
  name: string;
  value: string;
  onChange: (name: string, value: string) => void;
  isNonLocalizedDisabled?: boolean;
  idPrefix: string;
}

export default function CmsAttributeRichText(
  props: Readonly<CmsAttributeRichTextProps>
) {
  const { richTextClassName, richtextEditorSettings } = useCesStrRichText();
  return (
    <PbRichTextEditor
      id={props.idPrefix + "-" + props.name}
      className={richTextClassName}
      settings={richtextEditorSettings}
      onChange={(value: string) => props.onChange(props.name, value)}
      content={props.value}
    />
  );
}
