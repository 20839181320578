const HttpBackend = require("i18next-http-backend/cjs");
const ChainedBackend = require("i18next-chained-backend").default;
const LocalStorageBackend = require("i18next-localstorage-backend").default;

const isBrowser = typeof window !== "undefined";

// prettier-ignore
module.exports = {
  backend: {
    backendOptions: [{ expirationTime: 60 * 60 * 1000 }, {}], // 1 hour
    backends:
      typeof window !== "undefined" ? [LocalStorageBackend, HttpBackend] : [],
  },
  nonExplicitSupportedLngs: true,
  defaultNS: "public",
  ns: ["public"],
  partialBundledLanguages: isBrowser && true,
  serializeConfig: false,
  use: isBrowser ? [ChainedBackend] : [],
  i18n: {
    defaultLocale:
      process.env.PABU_PUBLIC_DEFAULT_LOCALE ||
      process.env.NEXT_PUBLIC_DEFAULT_LOCALE ||
      "en",
    locales: (
      process.env.PABU_PUBLIC_ACTIVE_LOCALES ||
      process.env.NEXT_PUBLIC_ACTIVE_LOCALES ||
      "en"
    ).split(","),
  },
};
