/*
 * //////////////////////////////////////////////
 * FUNCTIONS FOR SERVER SIDE ONLY
 * //////////////////////////////////////////////
 */
import { CustomTranslations, namespaces } from "@/types/translations";
import { getStrapiPrivateUrl } from "@/utils/api";
import { axiosGetRequestServerSide } from "@/utils/axiosServerUtil";
import { IncomingMessage, ServerResponse } from "http";
import jwt from "jsonwebtoken";
import { NextApiRequest, NextApiResponse } from "next";
import { NextApiRequestCookies } from "next/dist/server/api-utils";
import { TRANSLATION_NAMESPACE_PUBLIC } from "./constants";
import { isPageUrlRootUrl } from "./urlUtil";

/**
 * extracts the jwt CmsAccessToken string from
 * the request cookie "cmsToken"
 *
 * @param req
 * @returns token jwt string
 */
export const getCmsAccessToken = (req: IncomingMessage): string | undefined => {
  const request = req as IncomingMessage & {
    cookies: NextApiRequestCookies;
  };
  if (request && request.cookies && request.cookies.cmsToken) {
    return request.cookies.cmsToken;
  }
  return undefined;
};

/**
 * checks if the cms JWT access token is valid
 * NOTE: this function can only work on the server side
 */
export const isCmsAccessTokenValid = async (
  req: IncomingMessage & {
    cookies: NextApiRequestCookies;
  }
): Promise<boolean> => {
  const jwtAccessToken = getCmsAccessToken(req);
  if (!jwtAccessToken) {
    return false;
  }
  const cmsAccessTokenValid = await axiosGetRequestServerSide(
    getStrapiPrivateUrl("/pabu/auth/verify"),
    jwtAccessToken
  );
  return cmsAccessTokenValid.success;
};

/**
 * extracts the current locale from the NEXT_LOCALE cookie
 *
 *  IMPORTANT NOTE: In getServerSideProps / getStaticProps
 *  you should always use the locale that gets injected
 *  via the context object (getServerSideProps parameter)
 *  This is mandatory because the typed URL in the browser will set
 *  the locale cookie after loading the page
 *  (useLanguageCookie in layout.js)
 *
 * only use this function on API routes if needed.
 *
 * @param req
 * @returns the locale from the NEXT_LOCALE cookie
 *    or the default locale if the cookie is not set
 */
export const getLocaleFromCookie = (req: NextApiRequest): string => {
  if (req && req.cookies && req.cookies.NEXT_LOCALE) {
    return req.cookies.NEXT_LOCALE;
  }
  return global.envVar("PABU_PUBLIC_DEFAULT_LOCALE")!;
};

export const setCmsAuthenticationCookieToResponse = (
  res: NextApiResponse,
  cmsAccessToken: string
): void => {
  const decodedCmsAccessToken = jwt.decode(
    cmsAccessToken
  ) as jwt.JwtPayload | null;

  let expireDate = null;
  if (
    decodedCmsAccessToken &&
    "exp" in decodedCmsAccessToken &&
    decodedCmsAccessToken.exp
  ) {
    expireDate = new Date(decodedCmsAccessToken.exp * 1000);
  }

  const cmsTokenString = `cmsToken=${cmsAccessToken}; Path=/; ${
    global.envVar("PABU_PUBLIC_CURRENT_ENVIRONMENT") === "local"
      ? ""
      : "Secure; "
  }HttpOnly; SameSite=Strict; Expires=${expireDate}; Domain=${global.envVar(
    "PABU_PUBLIC_NEXTJS_DOMAIN"
  )}`;

  const cookieArray = [cmsTokenString];

  res.setHeader("Set-Cookie", cookieArray);
};

export const invalidateCmsAuthenticationCookie = (
  res: NextApiResponse | ServerResponse<IncomingMessage>
): void => {
  res.setHeader("Set-Cookie", [
    `cmsToken=deleted; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; Domain=${global.envVar(
      "PABU_PUBLIC_NEXTJS_DOMAIN"
    )}`,
  ]);
};

/**
 * creates a custom error object
 *
 * @param {*} status
 * @param {*} errorMsg
 * @param {*} message
 * @returns
 */
export const createErrorResponseObject = (
  status: number,
  errorMsg: string,
  message: string
): {
  status: number;
  error: string;
  message: string;
} => {
  return {
    status: status,
    error: errorMsg,
    message: message,
  };
};

/**
 * isNonDesktopDevice
 * Returns true if userAgent matches a non-desktop-Device.
 * Returns false otherwise.
 * @param {*} userAgent
 * @returns
 */
export const isNonDesktopDevice = (userAgent: string | undefined): boolean => {
  if (!userAgent) {
    return false;
  }

  return Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );
};

/**
 * findPrivateDefaultPage
 * @param {*} pages
 * @returns page or null
 */
export const findPrivateDefaultPage = (pages: Array<any>): any => {
  const privateDefaultPage = pages.find((page) => page.isPrivateDefault);
  return privateDefaultPage ? privateDefaultPage : null;
};

/**
 * findDefaultPage
 * @param {*} pages
 * @returns page or null
 */
export const findDefaultPage = (pages: Array<any>): any | null => {
  const defaultPage = pages.find((page) => isPageUrlRootUrl(page.url));
  return defaultPage ? defaultPage : null;
};

/**
 * Used in getServersideProps with serverSideTranslations(...) like this for example:
 *  ...(await serverSideTranslations(
          locale,
          getServerSideTranslationNamespaces(isAuthenticatedCmsUser, query.url[0], [
            "contenttype",
            "userapi"
          ])
        )),

   Adds the "public" namespace by default. 
   The "cms" namespace is added automatically, if the user is an authenticated cms user (see parameter)

   Additional namespaces can be loaded by adding it to the namespacesToInclude parameter.

   This function also checks if the current page is /news,/events or /joboffers, as those pages (with hardcoded urls) are handled
   by the dynamic route ([...url]). If we are on either of those pages, the contenttype namespace will be added automatically.

    
 * @param {*} isAuthenticatedCmsUser return of isUserAccessTokenValid(req);
 * @param {*} url query.url[0] object. Current page URL without leading slash(/)
 * @param {*} namespacesToInclude namespaces to be used for the current page
 * @returns Array of all namespaces to be included for the current page
 */
export const getServerSideTranslationNamespaces = (
  namespacesToInclude: Array<string> = []
): namespaces => {
  // public should always be enabled
  let namespaces: namespaces = [TRANSLATION_NAMESPACE_PUBLIC];
  return namespaces;
};

/**
 * sanitize dynamic API call paths
 * @param path
 * @returns
 */
export const sanitizePath = (path: string): string => {
  if (path.includes("..")) {
    path = path.replaceAll("..", "");

    if (path.includes("//")) {
      path = path.replaceAll("//", "/");
    }
  }
  if (!path.startsWith("/")) {
    path = "/" + path;
  }
  return path;
};

/**
 * Function that returns the CMS Translations in the locale set by the environment variable
 * @param isAuthenticatedCmsUser determines whether the user is authenticated as cms user or not
 * @returns CustomTranslations object
 */
export const getCmsTranslations = async (): Promise<CustomTranslations> => {
  let translations: CustomTranslations = {};
  translations = require(`../../public/locales/${global.envVar(
    "PABU_PUBLIC_CMS_USER_LANGUAGE"
  )}/cms.json`);
  return translations;
};

export const getExpireAndRefreshDateFromAccessToken = (
  token: string | undefined
) => {
  let expireDate = null;
  let refreshDate = null;

  if (token) {
    const currentAccessTokenData = jwt.decode(token) as jwt.JwtPayload | null;

    if (
      currentAccessTokenData &&
      "exp" in currentAccessTokenData &&
      "iat" in currentAccessTokenData &&
      typeof currentAccessTokenData.exp !== "undefined" &&
      typeof currentAccessTokenData.iat !== "undefined"
    ) {
      expireDate = new Date(currentAccessTokenData.exp * 1000);

      const accessTokenDuration =
        currentAccessTokenData.exp - currentAccessTokenData.iat;
      // modify this line to edit the refresh date
      refreshDate = new Date(
        (currentAccessTokenData.iat + accessTokenDuration / 2) * 1000
      );
    }
  }
  return { expireDate, refreshDate };
};
