import PbInputLabel from "@/components/input/pbInputLabel/pbInputLabel";
import withStyle from "@/middleware/withStyle";
import { getLanguageFromLocaleString } from "@/utils/localizationUtil";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import clsx from "clsx";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useState } from "react";

/**
 * Properties for the PbDateInput component.
 */
export interface PbDateInputProps {
  /**
   * The name of the input field.
   */
  name?: string;

  /**
   * The label for the input.
   */
  label?: string;

  /**
   * The selected date value.
   */
  value?: string | Date | null;

  /**
   * The minimum selectable date.
   */
  minDate?: Date;

  /**
   * The maximum selectable date.
   */
  maxDate?: Date;

  /**
   * Event handler for date change.
   */
  onChange: (value: string | null) => void;

  /**
   * input disabled
   */
  disabled?: boolean;

  fullWidth?: boolean;

  /**
   * Toggles usage of CmsThemeProvider and sets a class
   * @see withStyle
   */
  withCmsStyle?: boolean;

  /**
   * Toggles usage of PublicThemeProvider and sets a class
   */
  withPublicStyle?: boolean;
  id?: string;
}

/**
 * PbDateInput component for handling date input.
 * @param props - The component properties.
 * @returns JSX element representing the PbDateInput component.
 */
const PbDateInput = (props: PbDateInputProps) => {
  const router = useRouter();
  const [date, setDate] = useState(props.value || null);

  const validateInput = (value: any) => {
    let valid = true;

    if (value) {
      if (!value.isValid()) {
        valid = false;
      }

      if (valid) {
        props.onChange(value.format("YYYY-MM-DD"));
        setDate(value);
      } else {
        props.onChange(null);
        setDate(null);
      }
    } else {
      props.onChange(null);
      setDate(null);
    }
  };
  const minDate = props.minDate ? dayjs(props.minDate) : undefined;
  const maxDate = props.maxDate ? dayjs(props.maxDate) : undefined;

  return (
    <>
      {props.label && (
        <>
          <PbInputLabel label={props.label} />
          <br />
        </>
      )}
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={getLanguageFromLocaleString(router.locale)}
      >
        <DatePicker
          className={clsx(
            props.withCmsStyle && "with-cms-style",
            props.withPublicStyle && "with-public-style"
          )}
          disabled={props.disabled}
          minDate={minDate}
          maxDate={maxDate}
          name={props.name || ""}
          value={date === null ? null : dayjs(date)}
          onChange={(newValue: any) => {
            const newDayJsValue = dayjs(newValue);
            validateInput(newDayJsValue);
          }}
          slotProps={{
            field: { clearable: true },
            textField: { fullWidth: props.fullWidth, id: props.id },
          }}
        />
      </LocalizationProvider>
    </>
  );
};

export default withStyle(PbDateInput);
