import PbButton from "@/components/input/pbButton/pbButton";
import PbFileInput from "@/components/input/pbFileInput/pbFileInput";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { getFileById } from "@/services/cmsMediaManager/cmsMediaManagerService";
import { startFileUpload } from "@/services/upload/uploadService";
import {
  mediaManagerModalNewFolderAction,
  mediaManagerModalRefreshCurrentFolderAction,
} from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { cmsMediaManagerFileUpdateThunk } from "@/store/slices/cmsGeneral/cmsMediaManagerThunks";
import {
  hideLoadingOverlayAction,
  showLoadingOverlayAction,
} from "@/store/slices/general/generalSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import { useState } from "react";

interface CmsMediaManagerDetailActionButtonsProps {
  onNewFileChange: (e: any, file: any) => void;
}

export default function CmsMediaManagerDetailActionButtons(
  props: CmsMediaManagerDetailActionButtonsProps
) {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  const [isUploading, setIsUploading] = useState(false);

  const currentFolder = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.currentFolder
  );

  const handleMultipleFileUpload = async (files: File[]) => {
    if (isUploading) return;
    setIsUploading(true);
    const totalFiles = files.length;
    let uploadedFiles = 0;

    dispatch(
      showLoadingOverlayAction(`(1/${totalFiles}) ${tCms("uploadingFiles")}`)
    );

    try {
      for (const file of files) {
        uploadedFiles++;
        const fileUploadResult = await startFileUpload({
          file: file,
          dispatch: dispatch,
          hideLoadingOverlayOnFinish: false,
          overlayMessage: `(${uploadedFiles}/${totalFiles}) ${tCms(
            "uploadingFiles"
          )}`,
          fileNameOverride: file.name,
          fileInformation: {
            folder: currentFolder?.id,
          },
        });

        if (!fileUploadResult.success) {
          createToastError(tCms("managedFileCreateError"));
          continue;
        }

        if (fileUploadResult.data) {
          const createdFile = await getFileById(fileUploadResult?.data?.id);

          if (createdFile) {
            createdFile.pbFile.alternativeText = "";
            createdFile.pbFile.title = "";
            createdFile.pbFile.description = "";

            await dispatch(cmsMediaManagerFileUpdateThunk(createdFile, true));
            // dispatch(mediaManagerModalSelectFileAction(createdFile));
          }
        }
      }
      createToastSuccess(tCms("managedFileCreateSuccessMultiple"));
      dispatch(mediaManagerModalRefreshCurrentFolderAction());
    } catch (error) {
      createToastError(tCms("managedFileCreateError"));
    } finally {
      dispatch(hideLoadingOverlayAction());
      setIsUploading(false);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div>
        <PbFileInput
          onChange={(e, files) => {
            if (files.length === 1) {
              props.onNewFileChange(e, files[0]);
            } else {
              handleMultipleFileUpload(files);
            }
          }}
          resetOnClick
          multiple
        />
      </div>
      <PbButton
        onClick={() => dispatch(mediaManagerModalNewFolderAction())}
        startIcon={
          <PbIcon
            name="folder-light"
            className="svg-fill-white"
            height={20}
            width={20}
          />
        }
      >
        {tCms("managedFolderCreate")}
      </PbButton>
    </div>
  );
}
