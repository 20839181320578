import {
  createNavigationRequest,
  updateNavigationRequest,
} from "@/services/navigation/navigationService";
import { ReduxThunkAction } from "@/store/store";
import { deepImmutableCopy, getRandomSlug } from "@/utils/util";
import {
  hideLoadingOverlayAction,
  showLoadingOverlayAction,
} from "../general/generalSlice";
import { moveInArrayAction, updateAttributeAction } from "./cmsEditSlice";

export const cmsUpdateNavigationThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    const editNavigation = getState().cmsEdit.data.editNavigation;
    dispatch(showLoadingOverlayAction("updating navigation..."));
    const navigationUpdateResult = await updateNavigationRequest(
      editNavigation
    );
    dispatch(hideLoadingOverlayAction());

    if (navigationUpdateResult.success) {
      dispatch(
        updateAttributeAction({
          attributePath: "editNavigation",
          value: navigationUpdateResult.response?.data,
        })
      );
      dispatch(
        updateAttributeAction({
          attributePath: "originalNavigation",
          value: navigationUpdateResult.response?.data,
        })
      );
      return Promise.resolve();
    }
    return Promise.reject();
  };

export const cmsCreateNavigationThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    const editNavigation = getState().cmsEdit.data.editNavigation;
    dispatch(showLoadingOverlayAction("creating navigation..."));
    const navigationCreateResult = await createNavigationRequest(
      editNavigation
    );
    dispatch(hideLoadingOverlayAction());

    if (navigationCreateResult.success) {
      dispatch(
        updateAttributeAction({
          attributePath: "editNavigation",
          value: navigationCreateResult.response?.data,
        })
      );
      dispatch(
        updateAttributeAction({
          attributePath: "originalNavigation",
          value: navigationCreateResult.response?.data,
        })
      );
      return Promise.resolve();
    }
    return Promise.reject();
  };

export const cmsMoveUpDownNavigationThunk =
  (
    direction: "up" | "down",
    layer: number,
    layer1Pos: number,
    layer2Pos: number,
    layer3Pos: number
  ): ReduxThunkAction =>
  async (dispatch, getState) => {
    switch (layer) {
      case 1:
        dispatch(
          moveInArrayAction({
            attributePath: `editNavigation.pages[${layer1Pos}]`,
            directionOrIndex: direction,
          })
        );
        break;
      case 2:
        dispatch(
          moveInArrayAction({
            attributePath: `editNavigation.pages[${layer1Pos}].subPages[${layer2Pos}]`,
            directionOrIndex: direction,
          })
        );
        break;
      case 3:
        dispatch(
          moveInArrayAction({
            attributePath: `editNavigation.pages[${layer1Pos}].subPages[${layer2Pos}].subPages[${layer3Pos}]`,
            directionOrIndex: direction,
          })
        );
        break;
      default:
        return;
    }
  };

export const cmsMoveLayerUpDownNavigationThunk =
  (
    direction: "up" | "down",
    layer: number,
    layer1Pos: number,
    layer2Pos: number,
    layer3Pos: number
  ): ReduxThunkAction =>
  async (dispatch, getState) => {
    let updatedPagesArray = null;
    let newLayer = direction === "up" ? layer - 1 : layer + 1;
    let movedElement = null;
    let parentNodePos = null;

    const editNavigation = getState().cmsEdit.data.editNavigation;

    // in boundaries
    if (!(newLayer < 1) && !(newLayer > 3)) {
      switch (layer) {
        case 1:
          parentNodePos = layer1Pos - 1;
          updatedPagesArray = deepImmutableCopy([...editNavigation.pages]);
          movedElement = { ...updatedPagesArray[layer1Pos] };
          updatedPagesArray = [...updatedPagesArray].filter(
            (element, index) => index !== layer1Pos
          );
          if (newLayer === 2) {
            // you can only move down to layer 2 from here
            if (
              typeof updatedPagesArray[parentNodePos].subPages === "undefined"
            ) {
              updatedPagesArray[parentNodePos].subPages = [];
            }
            updatedPagesArray[parentNodePos].subPages = [
              ...updatedPagesArray[parentNodePos].subPages,
              movedElement,
            ];
          }
          break;
        case 2:
          parentNodePos = layer2Pos - 1;
          updatedPagesArray = deepImmutableCopy([...editNavigation.pages]);
          movedElement = {
            ...updatedPagesArray[layer1Pos].subPages[layer2Pos],
          };
          updatedPagesArray[layer1Pos].subPages = updatedPagesArray[
            layer1Pos
          ].subPages.filter(
            (element: any, index: number) => index !== layer2Pos
          );
          if (newLayer === 3) {
            // move down to layer 3
            if (
              typeof updatedPagesArray[layer1Pos].subPages[parentNodePos]
                .subPages === "undefined"
            ) {
              updatedPagesArray[layer1Pos].subPages[parentNodePos].subPages =
                [];
            }
            updatedPagesArray[layer1Pos].subPages[parentNodePos].subPages = [
              ...updatedPagesArray[layer1Pos].subPages[parentNodePos].subPages,
              movedElement,
            ];
          } else {
            movedElement.__component = "pb.nvtm";
            // delete id is necessary to trigger backend logic (old), tempId was added to handle render issues. (WEB-2155)
            delete movedElement.id;
            movedElement.tempId = getRandomSlug();
            // move up to layer 1
            updatedPagesArray = [
              ...updatedPagesArray.slice(0, layer1Pos + 1),
              movedElement,
              ...updatedPagesArray.slice(layer1Pos + 1),
            ];
          }
          break;
        case 3:
          updatedPagesArray = deepImmutableCopy([...editNavigation.pages]);
          movedElement = {
            ...updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages[
              layer3Pos
            ],
          };
          updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages =
            updatedPagesArray[layer1Pos].subPages[layer2Pos].subPages.filter(
              (element: any, index: number) => index !== layer3Pos
            );

          // you can only move up to layer 2 from here
          updatedPagesArray[layer1Pos].subPages = [
            ...updatedPagesArray[layer1Pos].subPages.slice(0, layer2Pos + 1),
            movedElement,
            ...updatedPagesArray[layer1Pos].subPages.slice(layer2Pos + 1),
          ];
          break;
        default:
          return;
      }
      dispatch(
        updateAttributeAction({
          attributePath: "editNavigation.pages",
          value: updatedPagesArray,
        })
      );
    }
  };
