import { MediaManagerFilterOption } from "@/components/cms/cmsMediaManager/cmsMediaManagerFilter";
import { LocalesStatus } from "@/services/cmsLocalizationService/cmsLocalizationService";
import { ContentElementStoreSetting } from "@/types/ceSettings/ceSettings";
import { PbPage } from "@/types/content-elements";
import {
  PbPageType,
  StrapiUploadFile,
  StrapiUploadFolder,
} from "@/types/strapi";
import { DEFAULT_MEDIA_CATEGORIES } from "@/utils/constants";
import { convertPropertyPath } from "@/utils/util";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import * as objectPath from "object-path";

export type EnabledListAttributesType = "title" | never;
export type EnabledListItemAttributesType =
  | "linkUrl"
  | "img"
  | "imgAlt"
  | "imgTitle"
  | "richTextContent"
  | "title"
  | "linkText"
  | "imgCaption"
  | never;
export interface CmsGeneralState {
  editMode: boolean;
  editView: boolean;
  currentContext: string | null;
  currentPropsPage: PbPage | null;
  allPages: Array<any>;
  pageTypes: Array<PbPageType>;
  pageAttributes: {
    [name: string]: any;
  };
  // Each instance of the popover generates a unique Id which is stored on open in this state.
  // The popoverOpenerId takes this Id from any popoverButton and shows/opens only the intended popover.
  popoverOpenerId: string | null;
  renderKey: string;
  createPageModal: {
    showCreatePageModal: boolean;
    selectedPageType: PbPageType | null;
  };
  addContentModal: {
    open: boolean;
    position: number | null;
  };
  configModal: {
    open: boolean;
    position: number | null;
    nestedPosition: number | null;
    contentElementSetting: ContentElementStoreSetting | null;
    lastTabValue: string;
  };
  nestedContentModal: {
    open: boolean;
    position: number | null;
  };
  dynamicListModal: {
    dynamicListEditorOpen: boolean;
    enabledListItemAttributes: Array<EnabledListItemAttributesType>;
    enabledListAttributes: Array<EnabledListAttributesType>;
    dynamicListDoesNotExist: boolean;
    dynamicListDisplayName: string;
    dynamicListName: string;
  };
  mediaManagerModal: CmsMediaManagerModalState;
  mediaSelectionModal: CmsMediaSelectionModalState;
  localesStatus: LocalesStatus;
  viewportPreview: string | null;
}

interface CmsMediaManagerModalState {
  open: boolean;
  itemSelection: boolean;
  mediaTypes: Array<MediaManagerFilterOption>;
  currentItem: StrapiUploadFolder | StrapiUploadFile | undefined;
  currentFolder: StrapiUploadFolder | undefined;
  detailView:
    | "NEW_FOLDER"
    | "EDIT_FOLDER"
    | "NEW_FILE"
    | "EDIT_FILE"
    | undefined;
  content: {
    files: Array<StrapiUploadFile>;
    folders: Array<StrapiUploadFolder>;
    parents: Array<StrapiUploadFolder>;
    selectItem: StrapiUploadFolder | StrapiUploadFile | undefined;
  };
  focus: boolean;
  locale: string | null;
  rootFolder: StrapiUploadFolder | undefined;
  triggerRefreshOnClose: boolean;
}

interface CmsMediaSelectionModalState {
  open: boolean;
  position: number | null;
}

export const initialState: CmsGeneralState = {
  editMode: false,
  editView: false,
  currentContext: null,
  currentPropsPage: null,
  allPages: [],
  pageTypes: [],
  pageAttributes: {},
  popoverOpenerId: null,
  renderKey: "",
  createPageModal: {
    showCreatePageModal: false,
    selectedPageType: null,
  },
  addContentModal: {
    open: false,
    position: null,
  },
  configModal: {
    open: false,
    position: null,
    nestedPosition: null,
    contentElementSetting: null,
    lastTabValue: "0",
  },
  nestedContentModal: {
    open: false,
    position: null,
  },
  mediaManagerModal: {
    open: false,
    itemSelection: false,
    mediaTypes: DEFAULT_MEDIA_CATEGORIES,
    currentItem: undefined,
    detailView: undefined,
    currentFolder: undefined,
    content: {
      files: [],
      folders: [],
      parents: [],
      selectItem: undefined,
    },
    focus: false,
    locale: null,
    rootFolder: undefined,
    triggerRefreshOnClose: false,
  },
  dynamicListModal: {
    dynamicListDisplayName: "", // display name which is only the contentmanager will be able to see
    dynamicListEditorOpen: false,
    dynamicListName: "",
    dynamicListDoesNotExist: false,
    enabledListItemAttributes: [],
    enabledListAttributes: [],
  },
  mediaSelectionModal: {
    open: false,
    position: null,
  },
  localesStatus: {
    localesInSync: true,
    strapiLocales: [],
    nextLocales: [],
  },
  viewportPreview: null,
};

export const cmsGeneralSlice = createSlice({
  name: "cmsGeneral",
  initialState,
  reducers: {
    setEditModeAction(state, action: PayloadAction<boolean>) {
      state.editMode = action.payload;
      state.editView = action.payload;
    },
    setEditViewAction(state, action: PayloadAction<boolean>) {
      state.editView = action.payload;
    },
    resetEditModeAction(state) {
      state.editMode = false;
      state.editView = false;
    },
    setCurrentContextAction(state, action: PayloadAction<string | null>) {
      state.currentContext = action.payload;
    },
    setCurrentPropsPage(state, action: PayloadAction<PbPage | null>) {
      state.currentPropsPage = action.payload;
    },
    showCreatePageModalAction(state, action: PayloadAction<number>) {
      state.createPageModal.showCreatePageModal = true;
      state.createPageModal.selectedPageType =
        state.pageTypes.find(
          (pagetype: PbPageType) => pagetype.id === action.payload
        ) ?? null;
    },
    hideCreatePageModalAction(state) {
      state.createPageModal.showCreatePageModal = false;
      state.createPageModal.selectedPageType = null;
    },
    showAddContentModal(state, action: PayloadAction<number | null>) {
      state.addContentModal.open = true;
      state.addContentModal.position = action.payload;
    },
    hideAddContentModal(state) {
      state.addContentModal.open = false;
      state.addContentModal.position = null;
    },
    showConfigModal(state, action: PayloadAction<number | null>) {
      state.configModal.open = true;
      state.configModal.position = action.payload;
    },
    showNestedConfigModal(
      state,
      action: PayloadAction<{
        position: number;
        nestedPosition: number;
        contentElementSetting?: ContentElementStoreSetting;
      }>
    ) {
      state.configModal.open = true;
      state.configModal.position = action.payload.position;
      state.configModal.nestedPosition = action.payload.nestedPosition;
      state.configModal.contentElementSetting =
        action.payload.contentElementSetting ?? null;
    },
    hideConfigModal(state) {
      state.configModal.open = false;
      state.configModal.position = null;
      state.configModal.nestedPosition = null;
      state.configModal.contentElementSetting = null;
      state.configModal.lastTabValue = "0";
    },
    showNestedContentModal(state, action: PayloadAction<number | null>) {
      state.nestedContentModal.open = true;
      state.nestedContentModal.position = action.payload;
    },
    hideNestedContentModal(state) {
      state.nestedContentModal.open = false;
      state.nestedContentModal.position = null;
    },
    refreshRenderKey(state) {
      state.renderKey = "" + new Date().getTime();
    },
    showMediaManagerModalAction(
      state,
      action: PayloadAction<{
        itemSelection?: boolean;
        mediaTypes?: MediaManagerFilterOption[];
        currentFolder?: StrapiUploadFolder;
        rootFolder?: StrapiUploadFolder;
      }>
    ) {
      state.mediaManagerModal.itemSelection =
        action.payload.itemSelection ?? true;
      state.mediaManagerModal.mediaTypes =
        action.payload.mediaTypes ?? DEFAULT_MEDIA_CATEGORIES;
      state.mediaManagerModal.open = true;
      state.mediaManagerModal.currentFolder =
        action.payload.currentFolder ?? undefined;
      state.mediaManagerModal.rootFolder =
        action.payload.rootFolder ?? undefined;
    },
    hideMediaManagerModalAction(state) {
      state.mediaManagerModal.open = false;
      state.mediaManagerModal.itemSelection = false;
      state.mediaManagerModal.mediaTypes = DEFAULT_MEDIA_CATEGORIES;
      state.mediaManagerModal.currentItem = undefined;
      state.mediaManagerModal.detailView = undefined;
      state.mediaManagerModal.currentFolder = undefined;
      state.mediaManagerModal.rootFolder = undefined;
      state.mediaManagerModal.content = {
        files: [],
        folders: [],
        parents: [],
        selectItem: undefined,
      };
      state.mediaManagerModal.locale = null;
    },
    mediaManagerModalNewFolderAction(state) {
      state.mediaManagerModal.detailView = "NEW_FOLDER";
      state.mediaManagerModal.currentItem = undefined;
    },
    mediaManagerModalNewFileAction(state) {
      state.mediaManagerModal.detailView = "NEW_FILE";
      state.mediaManagerModal.currentItem = undefined;
    },
    mediaManagerModalDiscardDetailView(state) {
      state.mediaManagerModal.detailView = undefined;
      state.mediaManagerModal.currentItem = undefined;
    },
    mediaManagerModalSelectFileAction(
      state,
      action: PayloadAction<StrapiUploadFile | undefined>
    ) {
      state.mediaManagerModal.currentItem = action.payload;
      state.mediaManagerModal.detailView = action.payload
        ? "EDIT_FILE"
        : undefined;
    },
    mediaManagerModalSelectFolderAction(
      state,
      action: PayloadAction<StrapiUploadFolder | undefined>
    ) {
      state.mediaManagerModal.currentItem = action.payload;
      state.mediaManagerModal.detailView = action.payload
        ? "EDIT_FOLDER"
        : undefined;
    },
    mediaManagerModalSetCurrentFolderAction(
      state,
      action: PayloadAction<StrapiUploadFolder | undefined>
    ) {
      state.mediaManagerModal.currentFolder = action.payload;
      state.mediaManagerModal.currentItem = undefined;
      state.mediaManagerModal.detailView = undefined;
    },
    mediaManagerModalSetRootFolderAction(state) {
      state.mediaManagerModal.currentFolder =
        state.mediaManagerModal.rootFolder;
    },
    mediaManagerModalRefreshCurrentFolderAction(
      state,
      action: PayloadAction<
        | {
            selectItem?: StrapiUploadFolder | StrapiUploadFile;
          }
        | undefined
      >
    ) {
      const oldFolder = {
        ...state.mediaManagerModal.currentFolder,
      } as StrapiUploadFolder;
      state.mediaManagerModal.currentFolder = undefined;
      state.mediaManagerModal.currentFolder = oldFolder;
      state.mediaManagerModal.detailView = undefined;
      state.mediaManagerModal.currentItem = undefined;
      if (action.payload) {
        state.mediaManagerModal.content.selectItem = action.payload.selectItem;
      }
    },
    mediaManagerTriggerRefreshOnClose(state) {
      state.mediaManagerModal.triggerRefreshOnClose = true;
    },
    updateAttributeAction(
      state,
      action: PayloadAction<{ attributePath: string; value: any }>
    ) {
      objectPath.set(
        state,
        `${convertPropertyPath(action.payload.attributePath)}`,
        action.payload.value
      );
    },
    openDynamicListModalAction(
      state,
      action: PayloadAction<{
        dynamicList: {
          dynamicListDisplayName: string;
          dynamicListName: string;
          enabledListAttributes: Array<EnabledListAttributesType>;
          enabledListItemAttributes: Array<EnabledListItemAttributesType>;
        };
      }>
    ) {
      state.dynamicListModal = {
        ...state.dynamicListModal,
        dynamicListDisplayName: action.payload.dynamicList
          .dynamicListDisplayName
          ? action.payload.dynamicList.dynamicListDisplayName
          : "",
        dynamicListName: action.payload.dynamicList.dynamicListName
          ? action.payload.dynamicList.dynamicListName
          : "",
        dynamicListEditorOpen: true,
        enabledListItemAttributes:
          action.payload.dynamicList.enabledListItemAttributes,
        enabledListAttributes: action.payload.dynamicList.enabledListAttributes,
      };
    },
    hideDynamicListModalAction(state) {
      state.dynamicListModal.dynamicListEditorOpen = false;
    },
    dynamicListExistsToggleAction(
      state,
      action: PayloadAction<{ dynamicListExists: boolean }>
    ) {
      state.dynamicListModal = {
        ...state.dynamicListModal,
        dynamicListDoesNotExist: !action.payload.dynamicListExists,
      };
    },
    showPopoverAction(state, action: PayloadAction<{ openerId: string }>) {
      state.popoverOpenerId = action.payload.openerId;
    },
    hidePopoverAction(state) {
      state.popoverOpenerId = null;
    },
    setCurrentContentElementSettingAction(
      state,
      action: PayloadAction<ContentElementStoreSetting | undefined>
    ) {
      state.configModal.contentElementSetting = action.payload ?? null;
    },
    showMediaSelectionModal(
      state,
      action: PayloadAction<{ position: number | null }>
    ) {
      state.mediaSelectionModal = {
        open: true,
        position: action.payload.position,
      };
    },
    hideMediaSelectionModal(state) {
      state.mediaSelectionModal = {
        open: false,
        position: null,
      };
    },
    setAllPages(state, action: PayloadAction<Array<any>>) {
      const allPages = action.payload ?? [];
      state.allPages = allPages;
      state.pageAttributes = allPages.length > 0 ? allPages[0].attributes : {};
    },
    setPageTypes(state, action: PayloadAction<Array<any>>) {
      state.pageTypes = action.payload ?? [];
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setEditModeAction,
  setEditViewAction,
  resetEditModeAction,
  setCurrentContextAction,
  setCurrentPropsPage,
  showCreatePageModalAction,
  hideCreatePageModalAction,
  showAddContentModal,
  hideAddContentModal,
  showConfigModal,
  hideConfigModal,
  showNestedConfigModal,
  showNestedContentModal,
  hideNestedContentModal,
  refreshRenderKey,
  showMediaManagerModalAction,
  hideMediaManagerModalAction,
  mediaManagerModalNewFolderAction,
  mediaManagerModalNewFileAction,
  mediaManagerModalDiscardDetailView,
  mediaManagerModalSelectFileAction,
  mediaManagerModalSelectFolderAction,
  mediaManagerModalSetCurrentFolderAction,
  mediaManagerModalSetRootFolderAction,
  mediaManagerModalRefreshCurrentFolderAction,
  mediaManagerTriggerRefreshOnClose,
  updateAttributeAction,
  openDynamicListModalAction,
  hideDynamicListModalAction,
  dynamicListExistsToggleAction,
  showPopoverAction,
  hidePopoverAction,
  hideMediaSelectionModal,
  showMediaSelectionModal,
  setCurrentContentElementSettingAction,
  setAllPages,
  setPageTypes,
} = cmsGeneralSlice.actions;

export default cmsGeneralSlice.reducer;
