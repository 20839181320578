import PbDateInput from "@/components/input/pbDateInput/pbDateInput";

interface CmsAttributeDateProps {
  name: string;
  value: string;
  onChange: (name: string, value: string) => void;
  isNonLocalizedDisabled?: boolean;
  idPrefix: string;
}

export default function CmsAttributeDate(
  props: Readonly<CmsAttributeDateProps>
) {
  return (
    <PbDateInput
      id={props.idPrefix + "-" + props.name}
      fullWidth
      withCmsStyle
      value={props.value}
      disabled={props.isNonLocalizedDisabled}
      onChange={(value: any) => props.onChange(props.name, value)}
    />
  );
}
