import { getEnvVar } from "@/services/pabuEnvVarService/pabuEnvVarService";
import pino from "pino";

const pinoConfig: any = {
  level:
    getEnvVar("PABU_PUBLIC_CURRENT_ENVIRONMENT") === "local" ? "debug" : "info",
  timestamp: pino.stdTimeFunctions.isoTime,
  formatters: {
    level: (label: string) => {
      return { level: label.toUpperCase() };
    },
    bindings(bindings: any) {
      return { pid: bindings.pid };
    },
  },
};

if (getEnvVar("PABU_PUBLIC_CURRENT_ENVIRONMENT") === "local") {
  pinoConfig.transport = {
    target: "pino-pretty",
    options: {
      colorize: true,
    },
  };
}

const logger = pino(pinoConfig);

export default logger;
