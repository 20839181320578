import PbGenericModal from "@/components/genericModal/pbGenericModal";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { CmsPropertyAttributeGroup } from "@/types/strapi";
import {
  DEFAULT_MEDIA_CATEGORIES,
  PROPERTY_ATTRIBUTE_BOOLEAN,
  PROPERTY_ATTRIBUTE_DATAPROVIDER,
  PROPERTY_ATTRIBUTE_DATE,
  PROPERTY_ATTRIBUTE_ENUM,
  PROPERTY_ATTRIBUTE_MEDIA,
  PROPERTY_ATTRIBUTE_RELATION,
  PROPERTY_ATTRIBUTE_RICHTEXT,
  PROPERTY_ATTRIBUTE_STRING,
  PROPERTY_ATTRIBUTE_TIME,
} from "@/utils/constants";
import { Col, Container, Row } from "react-bootstrap";

export interface CmsPropertyAttributeAddModalProps {
  open: boolean;
  attributeGroup: CmsPropertyAttributeGroup;
  newAttributeIndex: number;
  attributesLength: number;
  setOpen: (value: boolean) => void;
  onConfirm: (newAttributeIndex: number, newAttribute: any) => void;
}

const cmsPropertySchemaString = {
  name: "",
  label: "",
  description: "",
  type: PROPERTY_ATTRIBUTE_STRING,
  nonLocalized: false,
  default: "",
};

const cmsPropertySchemaRichtext = {
  name: "",
  label: "",
  description: "",
  type: PROPERTY_ATTRIBUTE_RICHTEXT,
  nonLocalized: false,
  default: "",
};

const cmsPropertySchemaBoolean = {
  name: "",
  label: "",
  description: "",
  type: PROPERTY_ATTRIBUTE_BOOLEAN,
  nonLocalized: false,
  default: false,
};
const cmsPropertySchemaDate = {
  name: "",
  label: "",
  description: "",
  type: PROPERTY_ATTRIBUTE_DATE,
  nonLocalized: false,
  default: "",
};
const cmsPropertySchemaTime = {
  name: "",
  label: "",
  description: "",
  type: PROPERTY_ATTRIBUTE_TIME,
  nonLocalized: false,
  default: "",
  optional: false,
};
const cmsPropertySchemaEnum = {
  name: "",
  label: "",
  description: "",
  type: PROPERTY_ATTRIBUTE_ENUM,
  nonLocalized: false,
  default: "",
  options: [{ name: "value1", value: "value1" }],
};
const cmsPropertySchemaMedia = {
  name: "",
  label: "",
  description: "",
  type: PROPERTY_ATTRIBUTE_MEDIA,
  nonLocalized: false,
  allowedTypes: DEFAULT_MEDIA_CATEGORIES,
};
const cmsPropertySchemaDataProvider = {
  name: "",
  label: "",
  description: "",
  type: PROPERTY_ATTRIBUTE_DATAPROVIDER,
  nonLocalized: false,
  url: "",
  multiple: false,
  labelAttribute: "",
  valueAttribute: "",
};
const cmsPropertySchemaRelation = {
  name: "",
  label: "",
  description: "",
  type: PROPERTY_ATTRIBUTE_RELATION,
  nonLocalized: false,
  model: "",
  localizedOptions: false,
  multiple: false,
  labelAttribute: "",
  valueAttribute: "",
  populated: true,
};

export default function CmsPropertyAttributeAddModal(
  props: CmsPropertyAttributeAddModalProps
) {
  const tCms = useCmsTranslation();

  const onConfirm = (newAttributeSchema: any) => {
    props.onConfirm(props.newAttributeIndex, {
      ...newAttributeSchema,
      name: `property${props.attributesLength + 1}`,
      label: `Property ${newAttributeSchema.type} ${
        props.attributesLength + 1
      }`,
    });
    props.setOpen(false);
  };

  return (
    <PbGenericModal
      className="cms-modal cms-property-editor-modal"
      open={props.open}
      title={tCms("add-attribute")}
      muiDialogProps={{
        fullWidth: true,
        maxWidth: "sm",
      }}
      onCancelClick={() => {
        props.setOpen(false);
      }}
      onCloseIconClick={() => {
        props.setOpen(false);
      }}
    >
      <Container fluid className="add-content-modal-wrapper">
        <Row>
          {[
            cmsPropertySchemaString,
            cmsPropertySchemaRichtext,
            cmsPropertySchemaBoolean,
            cmsPropertySchemaDate,
            cmsPropertySchemaTime,
            cmsPropertySchemaEnum,
            cmsPropertySchemaMedia,
            cmsPropertySchemaDataProvider,
            cmsPropertySchemaRelation,
          ].map((element, index) => (
            <Col key={index} className="col-auto">
              <div
                id={`pageType-attribute-type-${element.type}`}
                className="add-btn"
                onClick={() => onConfirm(element)}
              >
                <div className="add-btn-text">
                  <p className="text-center">
                    {tCms(`propertyattributeType-${element.type}`)}
                  </p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <style jsx>{`
        .add-btn {
          display: flex;
          background: #fff;
          border-radius: 2px;
          border: 1px solid #cdcdcd;
          height: 90px;
          width: 90px;
          margin: 1rem;
          position: relative;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
            0 1px 2px rgba(0, 0, 0, 0.24);
          transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
          user-select: none;

          .add-btn-text {
            margin: auto;
            p {
              color: var(--pb-black);
            }
          }
        }

        .add-btn:hover {
          box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25),
            0 5px 5px rgba(0, 0, 0, 0.22);
          cursor: pointer;
          background-color: #f2f2f2;
          border: 1px solid #bdbdbd;
        }

        .add-btn:active {
          background-color: #dbdbdb;
          border: 1px solid var(--pb-cms-color);
        }
      `}</style>
    </PbGenericModal>
  );
}
