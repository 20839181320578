import PbButton from "@/components/input/pbButton/pbButton";
import PbInputControlled from "@/components/input/pbInputControlled/pbInputControlled";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import { itemsByPath } from "@/services/cmsMediaManager/cmsMediaManagerService";
import { mediaManagerModalRefreshCurrentFolderAction } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import {
  cmsMediaManagerCreateFolderThunk,
  cmsMediaManagerDeleteFolderThunk,
  cmsMediaManagerUpdateFolderThunk,
} from "@/store/slices/cmsGeneral/cmsMediaManagerThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { StrapiUploadFolder } from "@/types/strapi";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import { useEffect, useState } from "react";

export default function CmsMediaManagerFolderDetails() {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  const currentItem = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.currentItem
  );
  const detailView = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.detailView
  );
  const currentFolder = useAppSelector(
    (state) => state.cmsGeneral.mediaManagerModal.currentFolder
  );

  const isEdit = detailView === "EDIT_FOLDER";
  const [folder, setFolder] = useState<Partial<StrapiUploadFolder> | undefined>(
    currentItem as StrapiUploadFolder
  );
  const { showConfirmModal } = useConfirmModal(async () => {
    try {
      const folderContent = await itemsByPath(folder?.path!, folder?.id!);
      if (
        folderContent.files.length !== 0 ||
        folderContent.folders.length !== 0
      ) {
        throw new Error();
      }
      await dispatch(cmsMediaManagerDeleteFolderThunk(folder?.id!));
      createToastSuccess(tCms("managedFolderDeleteSuccess"));
      dispatch(mediaManagerModalRefreshCurrentFolderAction());
    } catch (error) {
      createToastError(tCms("managedFolderDeleteError"));
    }
  });

  useEffect(() => {
    if (currentItem && "pathId" in currentItem) {
      setFolder(currentItem);
    } else {
      setFolder({ name: "" });
    }
  }, [currentItem]);

  const createFolder = async () => {
    if (!folder?.name?.trim()) {
      createToastError(tCms("managedFolderHasNoNameError"));
      return;
    }
    try {
      await dispatch(
        cmsMediaManagerCreateFolderThunk(
          folder.name.trim(),
          "",
          currentFolder?.id || null
        )
      );
      createToastSuccess(tCms("managedFolderCreateSuccess"));
      dispatch(mediaManagerModalRefreshCurrentFolderAction());
    } catch (error) {
      createToastError(tCms("managedFolderCreateError"));
    }
  };

  const updateFolder = async () => {
    if (!folder?.name?.trim() || !folder.id) {
      createToastError(tCms("managedFolderHasNoNameError"));
      return;
    }
    try {
      await dispatch(
        cmsMediaManagerUpdateFolderThunk(folder.id, folder.name.trim())
      );
      createToastSuccess(tCms("managedFolderUpdateSuccess"));
      dispatch(
        mediaManagerModalRefreshCurrentFolderAction({
          selectItem: folder as StrapiUploadFolder,
        })
      );
    } catch (error) {
      createToastError(tCms("managedFolderUpdateError"));
    }
  };

  const handleSaveEvent = () => {
    if (isEdit) {
      return updateFolder();
    } else {
      return createFolder();
    }
  };

  const handleDeleteEvent = async () => {
    if (!folder?.id) {
      return;
    }
    showConfirmModal({
      title: tCms("deleteManagedFolder"),
      content: tCms("deleteManagedFolderContent"),
      acceptBtnText: tCms("delete"),
      denyBtnText: tCms("cancel"),
      xIsDeny: true,
      icon: "circle-exclamation-light",
    });
  };

  return (
    <>
      <div className="media-manager-folder-form">
        <div>
          <PbInputControlled
            withCmsStyle
            autoFocus={detailView === "NEW_FOLDER"}
            label={tCms("managedFileModal-folderName")}
            placeholder={`${tCms("folderName")}...`}
            fullWidth
            value={folder?.name}
            onChange={(e) => {
              setFolder((prevState) => ({
                ...prevState,
                name: e.target.value,
              }));
            }}
          />
        </div>
        <div className="media-manager-folder-form-action-buttons">
          {isEdit && (
            <PbButton
              startIcon={
                <PbIcon
                  name="trash-light"
                  className="svg-fill-white"
                  height={20}
                  width={20}
                />
              }
              color="error"
              onClick={handleDeleteEvent}
            >
              {tCms("delete")}
            </PbButton>
          )}
          <PbButton
            startIcon={
              <PbIcon
                name="floppy-disk-light"
                className="svg-fill-white"
                height={20}
                width={20}
              />
            }
            onClick={handleSaveEvent}
          >
            {tCms("save")}
          </PbButton>
        </div>
      </div>
      <style jsx>{`
        .media-manager-folder-form {
          display: flex;
          flex-direction: column;
          gap: 1em;
          margin: 1rem;
          flex: 1;
        }
        .media-manager-folder-form-action-buttons {
          display: flex;
          gap: 1em;
        }
      `}</style>
    </>
  );
}
