import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { PbPage } from "@/types/content-elements";
import { PageElement } from "@/types/navigation";
import { StrapiUploadFile } from "@/types/strapi";
import { NextRouter } from "next/router";
import { getNextJsApiURL } from "./api";
import { ROOT_PAGE_URL, SEARCH_PAGE_URL } from "./constants";
import { isLocaleDefaultLocale } from "./localizationUtil";
import { removeSpecialCharacters, translateUmlaute } from "./util";

/**
 * Checks if external links should be opened in an external tab.
 * Internal links are always "_self".
 * @param {String} url internal or external URL
 */
export const getTargetValue = (url: string): string => {
  const isBlank = globalConfig?.seo?.openExternalLinksInNewTab;
  try {
    new URL(url);
  } catch (error) {
    return "_self";
  }
  return isBlank ? "_blank" : "_self";
};

/**
 * Returns a ***more*** DSGVO conform youtube url
 * @param {String} url e.g. "https://www.youtube.com/watch?v=jNQXAC9IVRw"
 * @returns youtube url or empty string if given url is invalid
 */
export const createDSGVOYoutubeUrl = (url: string): string => {
  let sanitizedUrl = "";
  if (url) {
    const regEx =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    const match = url.match(regEx);
    // ignore invalid youtube url
    if (match && match[2].length == 11) {
      sanitizedUrl =
        "https://www.youtube-nocookie.com/embed/" + match[2] + "?controls=0";
    }
  }
  return sanitizedUrl;
};

/**
 * Creates an URL-safe anchorString from given String.
 *  * @param {String} originalString
 */
export const createAnchorString = (originalString: string): string => {
  return removeSpecialCharacters(
    translateUmlaute(originalString.slice(0, 40).toLowerCase()),
    true
  ).replaceAll(" ", "-");
};

/**
 * checks if a link is external or internal.
 * If it is internal then the "/" is added to it
 * If the link is external (ie. starts with www.) then the "https://" is added to the link
 *
 * @param {string} linkToSanitize
 */
export const sanitizeLink = (linkToSanitize: string): string => {
  let sanitizedLink = null;
  const link = linkToSanitize || "";

  if (link.startsWith("www.")) {
    sanitizedLink = `https://${link}`;
  } else if (
    !link.startsWith("http") &&
    !link.startsWith("/") &&
    !link.startsWith("mailto:") &&
    !link.startsWith("javascript:")
  ) {
    sanitizedLink = `/${link}`;
  } else {
    sanitizedLink = link;
  }

  if (sanitizedLink === "/" + ROOT_PAGE_URL) {
    sanitizedLink = "/";
  }

  return sanitizedLink;
};

export const isPageUrlRootUrl = (url: string): boolean => {
  return url === ROOT_PAGE_URL;
};

export const isNavigationLinkActive = (
  router: NextRouter,
  page: PageElement
): boolean => {
  if (page.anchor) {
    const [routerPath, urlAnchor] = router.asPath.toLowerCase().split("#");

    if (urlAnchor !== page.anchor) {
      return false;
    }

    if (isPageUrlRootUrl(page.page.url)) {
      return page.page.url === routerPath;
    } else {
      return `/${page.page.url}` === routerPath;
    }
  } else {
    const routerPath = router.asPath.toLowerCase().split("#")[0];
    const url = page.externalUrl.length > 0 ? page.externalUrl : page.page.url;
    const isRootUrl = isPageUrlRootUrl(url);
    return isRootUrl
      ? routerPath === `/${url}` || routerPath === "/"
      : routerPath === `/${url}`;
  }
};

export const getAnchorFromUrl = (url: string) => {
  const splitUrl = url.split("#");

  const pageUrlWithoutAnchor = splitUrl[0];
  const pageUrlAnchor = splitUrl[1];

  return { url: pageUrlWithoutAnchor ?? "", anchor: pageUrlAnchor ?? "" };
};

/**
 * Gets the route from an url
 * 127.0.0.1:3000/events -> events
 * @param {*} url
 * @returns
 */
export const getRouteFromUrl = (url: string): string => {
  return url.slice(url.lastIndexOf("/") + 1);
};

export const getPathFromUrl = (url: string): string => {
  if (
    !url.startsWith("http://") &&
    !url.startsWith("https://") &&
    !url.startsWith("/")
  ) {
    url = "http://" + url;
  }

  if (url.startsWith("/")) {
    return url;
  }

  const path = url.split("/").slice(3).join("/");
  return path ? "/" + path : "/";
};

export const isRedirectUrlPointingToPageUrl = (page: PbPage) => {
  if (!page) {
    return false;
  }

  const currentUrl = getPageUrlOrRootUrl(page?.url);
  const redirectUrl = page?.redirectUrl;

  if (!redirectUrl) {
    return false;
  }

  const sanitizedRedirectUrl = sanitizeLink(redirectUrl);
  const domain = global.envVar("PABU_PUBLIC_NEXTJS_DOMAIN")!;

  const isSameDomain = sanitizedRedirectUrl.includes(domain);
  const isSamePath = getPathFromUrl(sanitizedRedirectUrl) === currentUrl;

  return redirectUrl === currentUrl || (isSameDomain && isSamePath);
};

export const addLocaleToRelativeUrl = (url: string, locale: string) => {
  if (url.startsWith("/") && !isLocaleDefaultLocale(locale)) {
    url = `/${locale}${url}`;
  }
  return url;
};

export const removeUrlQueryParamsFromWindowHistory = (): void => {
  if (typeof window !== "undefined") {
    const url = new URL(window.location.href);
    url.searchParams.forEach((value, key) => {
      // Remove all not GA / Matomo related query params
      if (!key.startsWith("utm_")) {
        url.searchParams.delete(key);
      }
    });
    // Do not remove search-related-Query-Params from WindowHistory.
    if (window.location.pathname !== SEARCH_PAGE_URL) {
      window.history.replaceState(
        null,
        "",
        url.pathname + url.search + window.location.hash
      );
    }
  }
};

export const removeUrlQueryParams = (url: string) => {
  let newUrl = url.includes("?") ? url.split("?")[0] : url;
  if (newUrl !== ROOT_PAGE_URL) {
    newUrl = newUrl.substring(1);
  }
  return newUrl;
};

export const removeUrlAnchor = (url: string) => {
  let newUrl = url.includes("#") ? url.split("#")[0] : url;
  return newUrl;
};

/**
 * This function returns the correct url from a page.
 * The root page "/" has as page.url ROOT_PAGE_URL.
 * So you need to check if your page is the root page.
 *
 * @param {string} url the page url
 * @returns
 */
export const getPageUrlOrRootUrl = (url: string): string => {
  if (isPageUrlRootUrl(url)) {
    return "/";
  }
  return `/${url}`;
};

export const isUrlDownloadUrl = (url: string): boolean => {
  if (url && url.includes(getNextJsApiURL(`/download`))) {
    return true;
  }
  return false;
};

/**
 * Generates a persistent download URL for a file.
 * The download URL uses the file ID, ensuring persistence even if the
 * file is changed or updated in the media manager.
 * The downloaded file will have the same name as specified in the media manager.
 *
 * @param {Object} file - The file object obtained from Strapi.
 * @returns {string} - The persistent download URL for the file or "".
 */
export const createPersistentDownloadUrlFromFile = (
  file: StrapiUploadFile
): string => {
  if (file) {
    return getNextJsApiURL(`/download/file/${file.id}`);
  } else {
    return "";
  }
};

/**
 * Generates a temporary download URL for a file.
 * The download URL provides direct access to the file. If the file
 * is changed or updated in the media manager, the URL will no longer work.
 * The downloaded file will have the same name as the original file.
 *
 * @param {Object} file - The file object
 * @returns {string} - The temporary download URL for the file or "".
 */
export const createTempDownloadUrlFromFile = (
  file: StrapiUploadFile
): string => {
  if (file) {
    return getNextJsApiURL(`/download${file.url}`);
  } else {
    return "";
  }
};
